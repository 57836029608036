import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'
import ElementPlus from 'element-plus'
import axios from './utils/http.js'
import router from './router'
import 'element-plus/dist/index.css'
import App from './App.vue'
import zh from '@/locales/zh.js'
import en from '@/locales/en.js'
const app = createApp(App)
const pinia = createPinia()
const messages = {
    en: {
        message:{
            ...en
        }
    },
    zh:{
        message:{
            ...zh
        }
    }
}
const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: localStorage.getItem('language') || 'zh',
    fallbackLocale:"zh",
    messages, // set locale messages
})
app.use(ElementPlus)
app.use(router)
app.use(pinia)
app.use(i18n)
app.provide("$axios",axios)
app.mount('#app')
