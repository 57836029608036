<template>
  <div class="app" id="app" :style="{ backgroundImage: 'url(' + require('@/assets/1.png') + ')' }">
    <div class="" :class="{ 'pc': rem.client_mode == 1, 'mobile': rem.client_mode == 2 }">
      <el-config-provider :locale="locale">
        <router-view></router-view>
      </el-config-provider>
    </div>

  </div>
</template>

<script setup>
import { useUserStore } from "@/stores/user.js"
import { useRectStore } from "@/stores/rect.js"
import { usePublicStore } from "@/stores/public.js"
import { useRem } from "@/stores/rem.js"
import { computed, ref, onMounted } from 'vue'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import en from 'element-plus/dist/locale/en.mjs'
const language = ref('zh-cn')
const locale = computed(() => (language.value === 'zh-cn' ? zhCn : en))
const user = useUserStore()
const rem = useRem()
const get_public = usePublicStore()
onMounted(() => {
  user.init()
  rem.useInit()
  get_public.getContext()
  const rectStore = useRectStore()
  rectStore.setBodyHeight(window.innerHeight)
  // KeFu.initialize(window.location.hostname, 'index')
})
</script>

<style lang="scss">
@import "@/styles/base.scss";

:root {
  --el-color-primary: #43F9FF;
}

.app {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: #1e1b1b;
  height: 100vh;
  overflow: auto;
  color: #fff;
}
</style>
