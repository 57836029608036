import {ref,reactive} from "vue"
import { defineStore } from 'pinia'
export const useDialogStore = defineStore('dialog_list', () => {
    const list = ref([])
    const eventObj = reactive({})
    const push=(params)=>{
        const index=list.value.findIndex(item=>item.name==params.name)
        if(index>=0){
            list.value[index].mode="show"
            list.value[index].params=params.params
        }
        else list.value.push(params)
    }
    const remove = (name)=>{
        const index=list.value.findIndex(item=>item.name==name)
        list.value.splice(index,1)
    }
    const hide = (name)=>{
        const index=list.value.findIndex(item=>item.name==name)
        list.value[index].mode="hide"
    }
    const show = (name)=>{
        const index=list.value.findIndex(item=>item.name==name)
        list.value[index].mode="show"
    }
    //监听事件
    const listen = (key,callback)=>{
        const event = new Event(key,{bubbles:false,cancelable:false});
        Object.assign(eventObj,{[key]:event})
        eventObj[key].handler=callback
        document.addEventListener(key,callback)
    }
    //派发事件
    const dispatch = (key,params)=>{
        if(!eventObj[key])return
        if(params)eventObj[key].params=params
        document.dispatchEvent(eventObj[key])
    }
    const removeEvent = (key)=>{
        if(!eventObj[key])return
        document.removeEventListener(key,eventObj[key].handler);
        eventObj[key]=null
    }
    return { list,push,remove,listen,dispatch,hide,show,removeEvent}
})