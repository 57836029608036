import axios from 'axios'
import { ElMessage } from 'element-plus'
// import Qs from 'qs'
import router from '../router'
import {useUserStore} from "@/stores/user.js"
import {baseRequest} from "@/utils/myutil.js"
axios.defaults.baseURL = baseRequest;
axios.defaults.timeout=60000;
// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers['Content-Type'] = 'application/json';
// 添加请求拦截器
axios.interceptors.request.use(
(config)=> {
    const user = useUserStore()
    config['headers']['token']=user.token
    // 在发送请求之前做些什么
    return config;
},
(error)=> {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(
(response)=> {
    if(response.status==200){
        if(response.data.code==401){
            //修改样式 临时注释
            router.replace({path:"/login"})
        }
        if(response.data.code==0){
            // ElMessage.error(response.data.msg);
            return Promise.reject(response.data);
        }
        // if(response.data.status==0){
        //     //项目需求特殊处理
        //     if(response.config.url=='/api/order/chat_order_list')return Promise.reject(response.data);
        //     ElMessage.error(response.data.msg);
        //     return Promise.reject(response.data);
        // }
    }
    // 对响应数据做点什么
    return Promise.resolve(response.data);
},
(error)=> {
    if(error.message.indexOf("timeout")!=-1){
        router.push("/timeout")
        return Promise.reject("服务器请求超时!");
    }
    const res = error.response
    if(res){
        if(res.data.code==0){
            if(res.data.message || res.data.msg){
                // ElMessage.error(res.data.message?res.data.message:res.data.msg);
            }
        }else if(error=='Error: Request failed with status code 400'){
            ElMessage.error("请求错误,错误码400");
        }else if(error=='Error: Request failed with status code 404'){
            console.log('没有发现文件、查询或URl');
        }else if(error=='Error: Request failed with status code 500'){
            console.log('服务器产生内部错误');
        }
        switch (res.status) {
            case 401:
                //修改样式 临时注释
                const user = useUserStore()
                user.logout()
                if (router.currentRoute.name != 'Login') {
                    router.replace({ name: 'Login'})
                }
                break
            case 400:
                if (res.data.code == 1) {
                    router.replace({ name: 'Login' })
                }
            default:
                if (res.data.redirect !== undefined) {
                    router.replace({ path: res.data.redirect })
                }
                break
        }
    }
    // 对响应错误做点什么
    return Promise.reject(res);
}
);

export default axios