<template>
    <div class="login h_center">
        <div class="">
            <div class="title h_center1 fn36 bold" v-if="rem.client_mode == 1">
                <img src="@/assets/2.png" class="logo" alt="">
                <span>欢迎您</span>
            </div>
            <div class="section rel">
                <div class="over abs ac bold" :class="{ 'fn30': rem.client_mode == 2, 'fn36': rem.client_mode == 1 }" v-if="seconds!=0">
                    <div class="">
                        {{$t('message.qingzai')}}
                        <span>&nbsp;{{timeObj.hour}}&nbsp;</span>
                        {{$t('message.shi')}}<span>&nbsp;{{timeObj.minute}}&nbsp;</span>
                        {{$t('message.fen')}}<span>&nbsp;{{timeObj.second}}&nbsp;</span>
                        {{$t('message.miao')}}{{$t('message.qian')}}{{$t('message.shiqu_jihui')}}
                    </div>
                </div>
                <div class="fn36 ending h_center bold" v-else>{{$t('message.benci_shixiao')}}</div>
                <div class="section-box" :class="{ 'flex wrap between h_center1': rem.client_mode == 1 }" v-if="seconds!=0">
                    <div class="box h_center rel">
                        <img src="@/assets/25.png" alt="">
                        <div class="title h_center bold abs" v-if="rem.client_mode == 2"
                            :class="{ 'fn48': rem.client_mode == 2, 'fn36': rem.client_mode == 1 }">
                            <img src="@/assets/2.png" class="logo" alt="">
                            <span>{{$t('message.huanying_nin')}}</span>
                        </div>
                    </div>
                    <div class="form">
                        <div class="h_center">
                            <div class="name rel ac">
                                <span class="fn24 bold rel" style="z-index:9;">{{$t('message.kaihu')}}</span>
                                <div class="line abs"></div>
                            </div>
                        </div>
                        <el-form :model="form" ref="formRef" :rules="rules" label-position="top" label-width="120px">
                            <!-- <el-form-item label="账户名称" prop="account" class="input-box">
                                <el-input v-model="form.account" placeholder="请输入账户名称" />
                            </el-form-item> -->
                            <el-form-item :label="$t('message.zhanghu_mima')" prop="password" class="input-box">
                                <div class="box1 rel" style="width:100%;" :style="rem.client_mode == 2 ? 'margin-top:-0.2rem;' : ''">
                                    <el-input v-model="form.password" :type="type" :placeholder="$t('message.qingshuru_mima')" />
                                    <div class="switch abs h_center cursor" @click="type = type == 'text' ? 'password' : 'text'">
                                        <img src="@/assets/26.png" alt="" v-if="type == 'password'">
                                        <img src="@/assets/26_1.png" alt="" v-if="type == 'text'">
                                    </div>
                                </div>
                            </el-form-item>
                            <el-form-item :label="$t('message.queren_mima')" class="input-box">
                                <div class="box1 rel" style="width:100%;" :style="rem.client_mode == 2 ? 'margin-top:-0.2rem;' : ''">
                                    <el-input v-model="password" :type="type_1" :placeholder="$t('message.qing_queren_mima')" />
                                    <div class="switch abs h_center cursor" @click="type_1 = type_1 == 'text' ? 'password' : 'text'">
                                        <img src="@/assets/26.png" alt="" v-if="type_1 == 'password'">
                                        <img src="@/assets/26_1.png" alt="" v-if="type_1 == 'text'">
                                    </div>
                                </div>
                            </el-form-item>
                        </el-form>
                        <div class="submit h_center fn24 bold cursor" @click="submit">{{$t('message.queren_kaihu')}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ElLoading, ElMessage } from 'element-plus'
import { reactive, inject, ref,onMounted,onUnmounted } from "vue"
import { useUserStore } from "@/stores/user.js"
import { useRouter,useRoute } from "vue-router"
import { useRem } from "@/stores/rem.js"

import moment from "moment"
const route = useRoute()
const seconds = ref(99999)
const timeObj = reactive({
    hour:"00",
    minute:"00",
    second:"00"
})
let timer = null
onMounted(()=>{
    if(route.query.exptime){
        Object.assign(form,{email:route.query.email,pid:route.query.pid,exptime:route.query.exptime})
        const a = moment()
        const b = moment(route.query.exptime*1000)
        // console.log(new Date().getTime()/1000)
        // console.log(route.query.exptime)
        seconds.value = b.diff(a, 'seconds')
        console.log(seconds.value)
        if(seconds.value<=0){
            seconds.value = 0
            Object.assign(timeObj,{
                second:"00"
            })
            return
        }
        timer = setInterval(() => {
            const a1 = moment()
            const b1 = moment(route.query.exptime*1000)
            seconds.value = b1.diff(a1, 'seconds')
            if(seconds.value<=0){
                seconds.value = 0
                Object.assign(timeObj,{
                    second:"00"
                })
                return
            }
            // seconds.value-=1
            const second=parseInt(seconds.value%60)>=10?parseInt(seconds.value%60):'0'+parseInt(seconds.value%60)
            const minute=parseInt(seconds.value/60%60)>=10?parseInt(seconds.value/60%60):'0'+parseInt(seconds.value/60%60)
            const hour=parseInt(seconds.value/3600%24)>=10?parseInt(seconds.value/3600%24):'0'+parseInt(seconds.value/3600%24)
            Object.assign(timeObj,{
                hour,minute,second
            })
        },1000)
    }else  seconds.value=0
    
})
onUnmounted(()=>{
    if(timer){
        clearInterval(timer)
        timer = null
    }
})
const rem = useRem()
const formRef = ref(null)
const form = reactive({
    email: "",
    password: "",
    pid:"",
    exptime:""
})
const type = ref('password')
const type_1 = ref('password')
const password = ref('')
const rules = reactive({
    email: [
        { required: true, message: '请输入邮箱', trigger: 'blur' },
    ],
    password: [
        { required: true, message: '请输入密码', trigger: 'blur' },
    ]
})

const $axios = inject("$axios")
const user = useUserStore()
const router = useRouter()
const submit = () => {
    let loading = null
    if (rem.client_mode == 1) {
        loading = ElLoading.service({
            lock: true,
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)',
        })
    }
    formRef.value.validate(async (valid) => {
        if (valid) {
            if(!password.value)return ElMessage.warning('请输入确认密码')
            if(password.value!=form.password)return ElMessage.warning('两次密码输入不一致')
            try {
                const result = await $axios.post("/api/user/activeUser", form)
                user.setAppUserToken(result.data.userinfo.token)
                user.getUserInfo()
                router.replace({ path: "/center" })
            } catch (e) { console.log(e) }
        }
    })
    if (rem.client_mode == 1) {
        loading.close()
    }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>