<template>
    <div class="tuoguan">
        <div class="t-head">
            <div class="title h_center1 fn24 bold">{{$t('message.wode_tuoguan')}}</div>
            <div class="tap flex wrap">
                <div class="button h_center fn18 bold cursor" @click="toggle">
                    <img src="@/assets/34.png" alt="">
                    <span>{{$t('message.zhanghu_tuoguan')}}</span>
                </div>
            </div>
        </div>
        <div class="list">
            <div class="" v-if="rem.client_mode == 1">
                <div class="son flex h_center1" v-for="(item, index) in list" :key="index">
                    <div class="user ac">
                        <img src="@/assets/logo.png" alt="">
                        <div class="name fn16 bold">{{ item.mt4_account }}</div>
                        <div class="status h_center fn14">{{ item.status == 1 ? $t('message.tuoguan_zhong') : item.status == 2 ? $t('message.jiechu_tuoguan') : $t('message.jinyong') }}
                        </div>
                    </div>
                    <div class="item ac F1">
                        <div class="val fn22 bold">{{ item.account_money ? item.account_money : 0.00 }}</div>
                        <div class="label fn14">{{$t('message.zhanghu_yue')}}(USD)</div>
                    </div>
                    <div class="item ac F1">
                        <div class="val fn22 bold green">+{{ item.new_income ? item.new_income : 0.00 }}</div>
                        <div class="label fn14">{{$t('message.zuixin_shouyi')}}</div>
                    </div>
                    <div class="item ac F1">
                        <div class="val fn22 bold">{{ item.sum_income ? item.sum_income : 0.00 }}</div>
                        <div class="label fn14">{{$t('message.leiji_shouyi')}}</div>
                    </div>
                    <div class="item ac F1">
                        <div class="val fn22 bold red">{{ item.seven_annualization ? item.seven_annualization : 0.00 }}%</div>
                        <div class="label fn14">{{$t('message.nianhua')}}</div>
                    </div>
                    <!-- <div class="photo ac">
                        <img src="@/assets/37.png" alt="">
                    </div> -->
                    <div class="curd">
                        <div class="btn h_center fn14 cursor bold" @click="sendMsg(item.mt4_account)">
                            <img src="@/assets/39.png" alt="">
                            <span>{{$t('message.chakan_jiaoyi')}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="" v-else>
                <div class="son" v-for="(item, index) in list" :key="index">
                    <div class="user flex h_center1">
                        <div class="context F1 flex h_center1">
                            <img src="@/assets/logo.png" alt="">
                            <div class="name fn24 bold">{{ item.mt4_account }}</div>
                            <div class="status fn24">{{ item.status == 1 ? $t('message.tuoguan_zhong') : item.status == 2 ? $t('message.jiechu_tuoguan') : $t('message.jinyong') }}</div>
                        </div>
                        <div class="btn fn24 h_center" @click="sendMsg(item.mt4_account)">{{$t('message.chakan_jiaoyi')}}</div>
                    </div>
                    <div class="look-panels flex fn24">
                        <div class="child F1 ac">
                            <div class="val">{{ item.account_money ? item.account_money : 0.00 }}</div>
                            <div class="label fn20">{{$t('message.zhanghu_yue')}}(USD)</div>
                        </div>
                        <div class="child F1 ac">
                            <div class="val green">+{{ item.new_income ? item.new_income : 0.00 }}</div>
                            <div class="label fn20">{{$t('message.zuixin_shouyi')}}</div>
                        </div>
                        <div class="child F1 ac">
                            <div class="val">{{ item.sum_income ? item.sum_income : 0.00 }}</div>
                            <div class="label fn20">{{$t('message.leiji_shouyi')}}</div>
                        </div>
                        <div class="child F1 ac">
                            <div class="val red">{{ item.seven_annualization ? item.seven_annualization : 0.00 }}</div>
                            <div class="label fn20">{{$t('message.nianhua')}}</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="flex flex-end h_center1 page" v-if="list.length!=0"><el-pagination background layout="prev, pager, next" @change="turnPage"
                :page-size="pagination.limit" :total="pagination.total" /></div>
        <div class="" v-if="show">
            <AddAcount @close="toggle" @refresh="fresh" :strategy_list="strategy_list" />
        </div>

        <!-- 交易记录 -->
        <div class="" v-if="showjl">
            <Record @close="close" />
        </div>

    </div>
</template>

<script setup>
import AddAcount from "@/components/center/AddAcount/AddAcount"
import { ref, inject, onMounted, onBeforeUnmount, onUnmounted } from 'vue'
import Record from "@/components/center/Record/Record"
const $axios = inject("$axios")

const strategy_list = ref([])

import { useSocket } from "@/stores/socket"
const socket = useSocket()
if (socket.socketInit) {
    socket.socketInit(`wss://ws.${window.location.hostname}`,(data) => {
        socket.list = data.ret
    })
}
onUnmounted(() => {
    if(socket.ws)socket.ws.close()
})
const showjl = ref(false)
const mt4_account = ref("")
const sendMsg = (account)=>{
    mt4_account.value = account
    socket.ws.send(`makfx&data+${mt4_account.value}`)
    showjl.value=true
}
const close = ()=>{
    socket.ws.send(`makfx&delete+${mt4_account.value}`)
    showjl.value = !showjl.value
}

import page from "@/api/page.js"
const { url, list, method, query, turnPage, pagination, isLoading, handleSizeChange } = page($axios)
onMounted(() => {
    url.value = "/api/account/my_trusteeship"
    Object.assign(query, { sort: 'new' })
    turnPage(pagination.page)
    getStrategyList()
    setTimeout(()=>{
        if(socket.ws)socket.ws.send('makfx')
    },1000)
})

const getStrategyList = () => {
    $axios.post("/api/strategy/strategy_list", {}).then(
        res => {
            // console.log(res)
            strategy_list.value = res.data
        },
        err => console.log(err)
    )
}

const handleSort = (val) => {
    console.log(pagination.total)
    Object.assign(query, { sort: val })
    pagination.page = 1
    turnPage(pagination.page)
}

const show = ref(false)
const toggle = () => {
    show.value = !show.value
}
const fresh = () => {
    show.value = false
    turnPage(pagination.page)
}

import { useRem } from "@/stores/rem.js"
const rem = useRem()
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>