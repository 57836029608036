import {ref} from "vue"
import { defineStore } from 'pinia'

export const useRectStore = defineStore('rect', () => {
    const body_height = ref(0)
    const setBodyHeight = (val)=>{
        body_height.value = val
    }
    return { 
        body_height,
        setBodyHeight
    }
})