<template>
    <div class="update fix h_center">
        <div class="form rel" v-if="!complete">
            <img src="@/assets/33.png" class="close abs" @click="close" alt="">
            <div class="item rel" v-if="!show_qrcode">
                <div class="label fn24 bold">{{$t('message.chongzhi_jine')}}</div>
                <input type="text" v-model="patment" :placeholder="$t('message.shuru_chongzhi_jine')" class="input fn20">
            </div>
            <div class="submit h_center fn28 bold" v-if="!show_qrcode" @click="submit">{{$t('message.tijiao')}}</div>
            <div class="recharge ac" v-else>
                <img :src="qrcode_url" alt="">
                <div class="over ac bold" :class="{ 'fn30': rem.client_mode == 2, 'fn36': rem.client_mode == 1 }">
                    <div class="" v-if="seconds!=0">
                        {{$t('message.qingzai')}}
                        <span>&nbsp;{{timeObj.hour}}&nbsp;</span>
                        {{$t('message.shi')}}<span>&nbsp;{{timeObj.minute}}&nbsp;</span>
                        {{$t('message.fen')}}<span>&nbsp;{{timeObj.second}}&nbsp;</span>
                        {{$t('message.miao')}}{{$t('message.qian')}}{{$t('message.wancheng_shixiao')}}
                    </div>
                    <div class="" v-else>{{$t('message.yishixiao_chongzhi')}}</div>
                </div>
                <div class="submit h_center fn28 bold" @click="submit" v-if="seconds==0">{{$t('message.shuaxin')}}</div>
            </div>
        </div>
        <div class="form rel" v-else>
            <img src="@/assets/33.png" class="close abs" @click="close" alt="">
            <div class="recharge ac">
                <div class="over ac bold complete" :class="{ 'fn30': rem.client_mode == 2, 'fn36': rem.client_mode == 1 }">
                    <span>{{$t('message.chongzhi_chenggong')}}</span>
                </div>
            </div>
        </div>

        <div class="" v-if="show_ask">
            <Ask :name="$t('message.tixing')" :text="msg" @close="show_ask=!show_ask" :confirm="$t('message.guanbi')" @confirm="show_ask=!show_ask" />
        </div>
    </div>
</template>

<script setup>
import {ref,inject,reactive,onUnmounted} from "vue"
const emits = defineEmits(['close','fresh'])
const close = ()=>{
    emits('close')
}
import {useUserStore} from "@/stores/user.js"
const user = useUserStore()

import { ElLoading, ElMessage } from 'element-plus'
import Ask from "@/components/Ask/Ask"
const show_ask = ref(false)
const $axios = inject("$axios")
const patment = ref("")
const msg = ref("")


import moment from "moment"
import { useRem } from "@/stores/rem.js"
const rem = useRem()
const show_qrcode = ref(false)
const qrcode_url = ref("")
const seconds = ref(99999)
const complete = ref(false)
const order_id = ref("")
const timeObj = reactive({
    hour:"00",
    minute:"00",
    second:"00"
})
let timer = null
const beginTimer = (times)=>{
    timer = setInterval(() => {
        const a1 = moment()
        const b1 = moment(times*1000)
        seconds.value = b1.diff(a1, 'seconds')
        if(seconds.value<=0){
            seconds.value = 0
            Object.assign(timeObj,{
                second:"00"
            })
            return
        }
        if(seconds.value%2==0)statusReg()
        const second=parseInt(seconds.value%60)>=10?parseInt(seconds.value%60):'0'+parseInt(seconds.value%60)
        const minute=parseInt(seconds.value/60%60)>=10?parseInt(seconds.value/60%60):'0'+parseInt(seconds.value/60%60)
        const hour=parseInt(seconds.value/3600%24)>=10?parseInt(seconds.value/3600%24):'0'+parseInt(seconds.value/3600%24)
        Object.assign(timeObj,{
            hour,minute,second
        })
    },1000)
}
const submit = async ()=>{
    seconds.value=9999
    if(timer){
        clearInterval(timer)
        timer = null
    }
    const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    try {
        const result = await $axios.post("/api/user/pay",{
            patment:patment.value,
        })
        qrcode_url.value = result.data.url
        show_qrcode.value=true
        order_id.value = result.data.order_id
        beginTimer(result.data.time)
        loading.close() 
        emits('fresh')
    } catch (e) {
        loading.close() 
        console.log(e) 
        msg.value = e.msg?e.msg:'操作失败'
        show_ask.value = true
    }
}

const statusReg = ()=>{
    $axios.get(`/api/order/orderInfo?order_id=${order_id.value}`,{}).then(
        res=>{
            if(res.data.status==1){
                complete.value=true
                user.getUserInfo()
            }
        },
        err=>console.log(err)
    )
}

onUnmounted(()=>{
    if(timer){
        clearInterval(timer)
        timer = null
    }
})
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>