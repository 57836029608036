<template>
    <div class="wallet">
        <div class="title fn24 bold">{{$t('message.wode_qianbao')}}</div>
        <div class="" v-if="rem.client_mode == 1">
            <div class="account h_center1">
                <div class="info ac">
                    <img src="@/assets/logo.png" alt="">
                    <div class="name">{{ userInfo.username }}</div>
                </div>
                <div class="copy mglf">
                    <div class="text bold">{{$t('message.wode_yue')}}：</div>
                    <div class="val flex wrap h_center1">
                        <span class="blue"><span class="big">{{usdt}}</span>USDT</span>
                        <div class="btn h_center bold cursor" @click="init('text')">{{$t('message.shuaxin')}}</div>
                    </div>
                </div>
                <div class="copy">
                    <div class="text bold">{{$t('message.qianbao_dizhi')}}：</div>
                    <div class="val flex wrap h_center1">
                        <span style="color:#A4A4A4;">{{userInfo.wallet_address}}</span>
                        <div class="btn h_center bold cursor" @click="copy">{{$t('message.shuaxin')}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="" v-else>
            <div class="account">
                <div class="info ac">
                    <img src="@/assets/logo.png" alt="">
                    <div class="name">{{ userInfo.username }}</div>
                </div>
                <div class="copy">
                    <div class="text bold">{{$t('message.wode_yue')}}：</div>
                    <div class="val flex wrap h_center1">
                        <span class="blue"><span class="big">{{usdt}}</span>USDT</span>
                        <div class="btn h_center bold cursor" @click="init('text')">{{$t('message.shuaxin')}}</div>
                    </div>
                </div>
                <div class="copy">
                    <div class="text bold">{{$t('message.qianbao_dizhi')}}：</div>
                    <div class="val flex wrap between h_center1">
                        <span style="color:#A4A4A4;">{{userInfo.wallet_address}}</span>
                        <div class="btn h_center bold cursor" style="margin-left:0;" @click="copy">{{$t('message.shuaxin')}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="list">
            <div class="title fn24 bold">{{$t('message.qianbao_shuoming')}}</div>
            <div class="account1 flex wrap between h_center1 fn18" v-html="get_public.public_info.wallet_desc"></div>
        </div>
    </div>
</template>

<script setup>
import {onMounted,inject,ref} from "vue"
import { storeToRefs } from 'pinia'
import {useUserStore} from "@/stores/user.js"
const {userInfo} = storeToRefs(useUserStore())
const clipboardObj = navigator.clipboard
onMounted(() => {
    init('')
    // setTimeout(() => {
    //     // alert(111)
    //     // socketsj.ws.send('makfx&data+31171064')
    // }, 1000)
})


const copy = ()=>{
    clipboardObj.writeText(String(userInfo.value.wallet_address)).then(
        res=>{
            alert("复制成功")
        }
    )
}

import { ElLoading, ElMessage } from 'element-plus'
const $axios = inject("$axios")
const usdt = ref(0)
const init = async (str)=>{
    const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    try {
        const result = await $axios.post("/api/user/usdtInfo",{})
        usdt.value = result.data.usdt
        if(str)alert("刷新成功")
    } catch (e) {}
    loading.close() 
}

import { usePublicStore } from "@/stores/public.js"
const get_public = usePublicStore()

import { useRem } from "@/stores/rem.js"
const rem = useRem()
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>