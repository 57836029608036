<template>
    <div class="tibi fix h_center">
        <div class="form rel">
            <img src="@/assets/33.png" class="close abs" @click="close" alt="">
            <div class="radios flex wrap between fn24 bold">
                <div class="child h_center" @click="type=1">
                    <img src="@/assets/50_1.png" v-if="type==1" class="icon" alt="">
                    <img src="@/assets/50.png" v-else class="icon" alt="">
                    <span>{{$t('message.neibu_zhuanzhang')}}</span>
                </div>
                <div class="child h_center" @click="setType(2)">
                    <img src="@/assets/50_1.png" v-if="type==2" class="icon" alt="">
                    <img src="@/assets/50.png" v-else class="icon" alt="">
                    <span>{{$t('message.zhuandao_qianbao')}}</span>
                </div>
                <div class="child h_center" @click="setType(3)">
                    <img src="@/assets/50_1.png" v-if="type==3" class="icon" alt="">
                    <img src="@/assets/50.png" v-else class="icon" alt="">
                    <span>{{$t('message.tibi')}}</span>
                </div>
            </div>
            <!-- 提币 -->
            <div class="" v-if="type==3">
                <div class="item rel">
                    <div class="label fn24 bold">{{$t('message.youxiang_yanzheng')}}</div>
                    <input type="text" v-model="ti_code" :placeholder="$t('message.shuru_yanzhengma')" class="input fn20">
                    <div class="btn fn24 h_center abs cursor bold" @click="send('withdraw')">{{$t('message.fasong_yanzhengma')}}</div>
                </div>
                <div class="item">
                    <div class="label fn24 bold">{{$t('message.tibi_jine')}}</div>
                    <input type="number" v-model="ti_money" :placeholder="$t('message.shuru_tibi_jine')" class="input fn20" >
                </div>
                <!-- <div class="item">
                    <div class="label fn24 bold">钱包地址</div>
                    <input type="text" placeholder="输入钱包地址" class="input fn20" >
                </div> -->
            </div>
            <!-- 内部转账 -->
            <div class="" v-if="type==1">
                <div class="item rel">
                    <div class="label fn24 bold">{{$t('message.youxiang_yanzheng')}}</div>
                    <input type="text" v-model="insde_code" :placeholder="$t('message.shuru_yanzhengma')" class="input fn20">
                    <div class="btn fn24 h_center abs cursor bold" @click="send('transfer')">{{$t('message.fasong_yanzhengma')}}</div>
                </div>
                <div class="item">
                    <div class="label fn24 bold">{{$t('message.zhuanzhang_jine')}}</div>
                    <input type="number" v-model="insde_money" :placeholder="$t('message.shuru_zhuanzhang_jine')" class="input fn20" >
                </div>
                <div class="item">
                    <div class="label fn24 bold">{{$t('message.duifang_youxiang')}}</div>
                    <input type="text" v-model="insde_email" :placeholder="$t('message.shuru_duifang_youxiang')" class="input fn20" >
                </div>
            </div>
            <!-- 转到钱包 -->
            <div class="" v-if="type==2">
                <div class="item rel">
                    <div class="label fn24 bold">{{$t('message.youxiang_yanzheng')}}</div>
                    <input type="text" v-model="w_code" :placeholder="$t('message.shuru_yanzhengma')" class="input fn20">
                    <div class="btn fn24 h_center abs cursor bold" @click="send('transfer')">{{$t('message.fasong_yanzhengma')}}</div>
                </div>
                <div class="item">
                    <div class="label fn24 bold">{{$t('message.zhuanzhang_jine')}}</div>
                    <input type="number" v-model="w_money" :placeholder="$t('message.shuru_zhuanzhang_jine')" class="input fn20" >
                </div>
            </div>
            <div class="submit h_center fn28 bold" @click="submit">{{$t('message.tijiao')}}</div>
        </div>
        <div v-if="show_ask">
            <Ask :name="$t('message.tixing')" :text="$t('message.mashang_jihuo')" @close="show_ask=!show_ask" :confirm="$t('message.liji_jihuo')" @confirm="jihuo" />
        </div>
    </div>
</template>

<script setup>
import {ref,inject} from "vue"
const emits = defineEmits(['close','fresh'])
const close = ()=>{
    emits('close')
}

import {useUserStore} from "@/stores/user.js"
const user = useUserStore()

import { ElLoading, ElMessage,ElMessageBox  } from 'element-plus'
const $axios = inject("$axios")
const code = ref("")
const send = async (_event)=>{
    const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    try {
        const result = await $axios.post("/api/ems/commonCode",{email:user.userInfo.username,event:_event})
        loading.close() 
        ElMessage.success(result.msg?result.msg:'发送成功')
    } catch (e) {
        loading.close() 
        console.log(e) 
    }
}

import Ask from "@/components/Ask/Ask"
const type = ref(1)
const show_ask = ref(false)
const setType = (val)=>{
    if(user.userInfo.is_wallet!=1)return show_ask.value = true
    type.value = val
}
const jihuo = async ()=>{
    const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    try {
        const result = await $axios.post("/api/user/wallet",{})
        loading.close()
        alert(result.msg?result.msg:'激活成功')
        user.getUserInfo()
    } catch (e) {
        loading.close() 
        console.log(e) 
    }
}

const submit = () =>{
    if(type.value == 3)tiSubmit()
    else if(type.value==1)insdeSubmit()
    else wSubmit()
}

//提币提交
const ti_code = ref("")
const ti_money = ref("")
const tiSubmit = async ()=>{
    if(ti_code.value==""){
        alert("请输入邮箱验证码")
        return
    }
    if(ti_money.value==""){
        alert("请输入提币数额")
        return
    }
    const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    try {
        const result = await $axios.post("/api/user/withdraw",{
            code:ti_code.value,
            money:ti_money.value
        })
        loading.close()
        user.getUserInfo()
        alert(result.msg?result.msg:'提币成功')
        emits("fresh")
    } catch (e) {
        loading.close() 
        console.log(e) 
        alert(e.msg?e.msg:'操作失败')
    }
}

//内部转账
const insde_code = ref("")
const insde_money = ref("")
const insde_email = ref("")
const insdeSubmit = async ()=>{
    if(insde_code.value==""){
        alert("请输入邮箱验证码")
        return
    }
    if(insde_money.value==""){
        alert("请输入提币数额")
        return
    }
    if(insde_email.value==""){
        alert("请输入对方邮箱")
        return
    }
    const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    try {
        const result = await $axios.post("/api/user/transfer",{
            code:insde_code.value,
            money:insde_money.value,
            email:insde_email.value
        })
        loading.close()
        user.getUserInfo()
        alert(result.msg?result.msg:'转账成功')
        emits("fresh")
    } catch (e) {
        loading.close() 
        console.log(e) 
        alert(e.msg?e.msg:'操作失败')
    }
}

//转到钱包
const w_code = ref("")
const w_money = ref("")
const wSubmit = async ()=>{
    if(w_code.value==""){
        alert("请输入邮箱验证码")
        return
    }
    if(w_money.value==""){
        alert("请输入转账数额")
        return
    }
    const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    try {
        const result = await $axios.post("/api/user/transferout",{
            code:w_code.value,
            money:w_money.value
        })
        loading.close()
        user.getUserInfo()
        alert(result.msg?result.msg:'转账成功')
        emits("fresh")
    } catch (e) {
        loading.close() 
        console.log(e) 
        alert(e.msg?e.msg:'操作失败')
    }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>