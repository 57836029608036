<template>
    <div class="ac">
        <h1 style="margin-top:200px;">糟糕，页面走丢了!- -</h1>
        <el-button type="primary" @click="back">返回首页</el-button>
    </div>
</template>

<script setup>
import {useRouter} from "vue-router"
const router = useRouter()

const back =()=>{
    router.replace("/")
}
</script>