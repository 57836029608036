<template>
    <div class="figures" id="figures">

    </div>
</template>

<script setup>
  import {ref,onMounted,onUnmounted} from "vue"
  import {useDialogStore} from "@/stores/dialog_list.js"
  import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
  import * as THREE from 'three'
  import utils from "./utils.js"
  const dialog = useDialogStore()
  onMounted(()=>{
      threeInit()
      dialog.listen("onresize",()=>{
        resize()
      })
  })
  const resize = ()=>{
    const height = figures.offsetHeight
    const width = figures.offsetWidth
    renderer.setSize(width, height)
    camera.aspect = width / height
    camera.updateProjectionMatrix()
  }
  
  let figures = null
  let renderer = null
  let camera = null
  let group = null
  let scene = null
  let circular_one = null
  let circular_two = null
  onUnmounted(()=>{
    dialog.removeEvent("onresize")
    cancelAnimationFrame(render)
    group.traverse((obj)=> {
        if (obj.type === 'Mesh') {
        obj.geometry.dispose();
        obj.material.dispose();
      }
    })
    scene.traverse((v) => {
			  if (v.type === 'Mesh') {
          v.geometry.dispose()
          v.material.dispose()
        }
		})
  })
  const {initFigures,FiguresStart,drawCircular,starScene}  = utils()
  const render = ()=>{
    FiguresStart(group)
    circular_one.rotateY(0.003)
    circular_two.rotateY(-0.005)
    renderer.render(scene, camera) //执行渲染操作
    requestAnimationFrame(render)//请求再次执行渲染函数render，渲染下一帧
  }
  const threeInit = ()=>{
    scene = new THREE.Scene()
    const ambient = new THREE.AmbientLight(0xffffff, 0.4)
    scene.add(ambient)
    figures = document.getElementById('figures')
    const height =figures.offsetHeight
    const width = figures.offsetWidth
    camera = new THREE.PerspectiveCamera(30,  width/ height, 1, 3000)
    camera.position.set(150,150, 150);
    camera.lookAt(0, 0, 0);
    renderer = new THREE.WebGLRenderer({
        antialias:true,
        alpha: true
    })
    renderer.setPixelRatio(window.devicePixelRatio)
    renderer.setSize(width, height) //设置three.js渲染区域的尺寸(像素px)
    const controls = new OrbitControls(camera, renderer.domElement)
    controls.update()//update()函数内会执行camera.lookAt(controls.targe)
    controls.enablePan = false
    controls.enabled =false
    controls.enableRotate  =false
    controls.enableZoom =false
    document.getElementById('figures').appendChild(renderer.domElement)
    group = initFigures()
    scene.add(group)
    circular_one = drawCircular()
    circular_two = drawCircular(70,75)
    scene.add(circular_one)
    scene.add(circular_two)
    const cloud = starScene()
    scene.add(cloud)
    render()
}
</script>

<style lang="scss" scoped>
.pc{
  .figures{
      width: 100%;
      height: 500px;
  }
}
.mobile{
  .figures{
      width: 100%;
      height: 5rem;
  }
}

</style>