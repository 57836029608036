<template>
    <div class="add-acount fix h_center">
        <div class="content">
            <div class="title flex wrap between h_center1">
                <span class="fn24 bold">{{$t('message.zhanghao_tuoguan')}}</span>
                <img src="@/assets/33.png" @click="close" class="icon cursor" alt="">
            </div>
            <div class="form">
                <el-form :model="form" ref="formRef" :rules="rules" label-position="top" label-width="120px">
                    <el-form-item :label="$t('message.shuru_fuwuqi_dizhi')" prop="server_address" class="input-box">
                        <el-input v-model="form.server_address" :placeholder="$t('message.shuru_fuwuqi_dizhi')" />
                    </el-form-item>
                    <el-form-item :label="$t('message.shuru_mt4_zhanghao')" prop="mt4_account" class="input-box">
                        <el-input v-model="form.mt4_account" :placeholder="$t('message.shuru_mt4_zhanghao')" />
                    </el-form-item>
                    <el-form-item :label="$t('message.shuru_mt4_mima')" prop="mt4_password" class="input-box">
                        <el-input v-model="form.mt4_password" type="password" :placeholder="$t('message.shuru_mt4_mima')" />
                    </el-form-item>
                    <el-form-item :label="$t('message.xuanze_celue')" prop="strategy_id" class="input-box">
                        <el-select
                            v-model="form.strategy_id"
                            class="m-2"
                            size="large"
                            @change="handleStrategy"
                        >
                            <el-option
                            v-for="item in strategy_list"
                            :key="item.id"
                            :label="item.strategy_name"
                            :value="item.id"
                            />
                        </el-select>
                    </el-form-item>
                </el-form>
                <div class="h_center">
                    <div class="submit h_center fn20 bold cursor" @click="submit">{{$t('message.tijiao')}}</div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script setup>
import {reactive,ref,inject} from "vue"

const formRef = ref(null)
const $axios = inject("$axios")
const props = defineProps({
    strategy_list:Array
})
const form = reactive({
    strategy_id:"",//策略id
    mt4_password:"",//mt4密码
    mt4_account:"",//mt4账号
    server_address:"",//服务器地址
})
const rules =  reactive({
    server_address: [
        { required: true, message:'请输入服务器地址',trigger: 'blur' },
    ],
    mt4_account:[
        { required: true, message:'请输入mt4账号',trigger: 'blur' },
    ],
    mt4_password:[
        { required: true, message:'请输入mt4密码',trigger: 'blur' },
    ],
    strategy_id:[
        { required: true, message:'请选择策略',trigger: 'change' },
    ]
})
const handleStrategy = (val)=>{
    Object.assign(form,{strategy_id:val})
}
import { ElLoading,ElMessage } from 'element-plus'
const submit = ()=>{
    const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    formRef.value.validate(async (valid)=>{
        if(valid){
            try{
                const result = await $axios.post("/api/account/trusteeship",form)
                ElMessage.success(result.msg)
                emit("refresh")
            }catch(e){console.log(e)}
        }
    })
    loading.close()
}
const emit = defineEmits(['close'])
const close = ()=>{
    emit("close")
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>