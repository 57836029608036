<template>
    <div class="update fix h_center">
        <div class="form rel" v-if="!complete">
            <img src="@/assets/33.png" class="close abs" @click="close" alt="">
            <div class="recharge ac">
                <img :src="qrcode_url" class="cursor" @click="copy" alt="">
                <div class="ac fn24 cursor" @click="copy">{{address}}</div>
                <div class="over ac bold" :class="{ 'fn30': rem.client_mode == 2, 'fn36': rem.client_mode == 1 }">
                    <div class="" v-if="seconds!=0">
                        {{$t('message.qingzai')}}
                        <span>&nbsp;{{timeObj.hour}}&nbsp;</span>
                        {{$t('message.shi')}}<span>&nbsp;{{timeObj.minute}}&nbsp;</span>
                        {{$t('message.fen')}}<span>&nbsp;{{timeObj.second}}&nbsp;</span>
                        {{$t('message.miao')}}{{$t('message.qian')}}{{$t('message.wancheng_shixiao')}}
                    </div>
                    <div class="" v-else>{{$t('message.yishixiao_chongzhi')}}</div>
                </div>
                <div class="submit h_center fn28 bold" @click="submit" v-if="seconds==0">{{$t('message.shuaxin')}}</div>
            </div>
        </div>
        <div class="form rel" v-else>
            <img src="@/assets/33.png" class="close abs" @click="close" alt="">
            <div class="recharge ac">
                <div class="over ac bold complete" :class="{ 'fn30': rem.client_mode == 2, 'fn36': rem.client_mode == 1 }">
                    <span>{{$t('message.chongzhi_chenggong')}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref,inject,reactive,onUnmounted,onMounted} from "vue"
const emits = defineEmits(['close','fresh','submit'])
const close = ()=>{
    emits('close')
}
const submit = async ()=>{
    emits('submit')
    emits('close')
}

import {useUserStore} from "@/stores/user.js"
const user = useUserStore()

import moment from "moment"
import { useRem } from "@/stores/rem.js"
const rem = useRem()
const show_qrcode = ref(false)
const seconds = ref(99999)
const complete = ref(false)
const order_id = ref("")
const timeObj = reactive({
    hour:"00",
    minute:"00",
    second:"00"
})
const props = defineProps({
    qrcode_url:String,
    times:String,
    address:String,
})
onMounted(()=>{
    show_qrcode.value = true
    beginTimer()
})
let timer = null
const beginTimer = ()=>{
    timer = setInterval(() => {
        const a1 = moment()
        const b1 = moment(props.times*1000)
        seconds.value = b1.diff(a1, 'seconds')
        if(seconds.value<=0){
            seconds.value = 0
            Object.assign(timeObj,{
                second:"00"
            })
            return
        }
        if(seconds.value%2==0)statusReg()
        const second=parseInt(seconds.value%60)>=10?parseInt(seconds.value%60):'0'+parseInt(seconds.value%60)
        const minute=parseInt(seconds.value/60%60)>=10?parseInt(seconds.value/60%60):'0'+parseInt(seconds.value/60%60)
        const hour=parseInt(seconds.value/3600%24)>=10?parseInt(seconds.value/3600%24):'0'+parseInt(seconds.value/3600%24)
        Object.assign(timeObj,{
            hour,minute,second
        })
    },1000)
}
const $axios = inject("$axios")
const statusReg = ()=>{
    $axios.get(`/api/order/orderInfo?order_id=${order_id.value}`,{}).then(
        res=>{
            if(res.data.status==1){
                complete.value=true
                user.getUserInfo()
            }
        },
        err=>console.log(err)
    )
}
const clipboardObj = navigator.clipboard
const copy = ()=>{
    clipboardObj.writeText(String(props.address)).then(
        res=>{
            alert("复制成功")
        }
    )
}

onUnmounted(()=>{
    if(timer){
        clearInterval(timer)
        timer = null
    }
})
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>