import { createRouter,createWebHashHistory} from 'vue-router'

import Home from "@/views/home/home"
import Login from "@/views/login/login"
import InviteLogin from "@/views/invite_login/invite_login"
import Center from "@/views/center/center"
import Error from "@/views/error/error"
const router = createRouter({
  history:createWebHashHistory(),
  routes: [
    { 
      path: '/', 
      name: 'Home', 
      component: Home,
    },{ 
      path: '/login', 
      name: 'Login', 
      component: Login,
    },{ 
      path: '/center', 
      name: 'Center', 
      component: Center,
    },{ 
      path: '/invite_login', 
      name: 'InviteLogin', 
      component: InviteLogin,
    },{ 
      path: '/error', 
      name: 'Error', 
      component: Error,
    }
  ]
});

export default router