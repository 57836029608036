<template>
    <div class="record fix h_center">
        <div class="content">
            <div class="title flex wrap between h_center1">
                <span class="fn24 bold">{{$t('message.chakan_zhanghu_xinxi')}}</span>
                <img src="@/assets/33.png" @click="close" class="icon cursor" alt="">
            </div>
            <div class="box">
                <el-table :data="list" height="400" :header-row-class-name="tableHeaderClassName" :row-class-name="tableRowClassName" style="width: 100%">
                    <el-table-column prop="server_address" align="center" :label="$t('message.shuru_fuwuqi_dizhi')" />
                    <el-table-column prop="mt4_account" align="center" :label="$t('message.shuru_mt4_zhanghao')" />
                    <el-table-column prop="选择策略" align="center" :label="$t('message.xuanze_celue')" >
                      <template #default="scope">
                          <div>{{ trans_strategy(scope.row.strategy_id) }}</div>
                      </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="h_center page"><el-pagination :page-size="pagination.limit" background layout="prev, pager, next" :total="pagination.total" /></div>
        </div>
    </div>
</template>

<script setup>
import {ref,inject,onMounted} from "vue"
const tableRowClassName = ({row,rowIndex})=>{
    if ((rowIndex+1)%2==0)return 'hover fn20'
    return "fn20"
}
const tableHeaderClassName = ({})=>{
    return "fn20"
}

import page from "@/api/page.js"
const $axios = inject("$axios")
const { url, list, method, query, turnPage, pagination, isLoading, handleSizeChange } = page($axios)
const props = defineProps({
  user_id:String
})
onMounted(() => {
    url.value = "/api/user/teamMt4"
    method.value = "get"
    Object.assign(query, { user_id: props.user_id })
    turnPage(pagination.page)
    getStrategyList()
})
const strategy_list = ref([])
const getStrategyList = () => {
    $axios.post("/api/strategy/strategy_list", {}).then(
        res => {
            // console.log(res)
            strategy_list.value = res.data
        },
        err => console.log(err)
    )
}
const trans_strategy = (id)=>{
  const item = strategy_list.value.find(item=>item.id==id)
  return item.strategy_name
}

const emit = defineEmits(['close'])
const close = ()=>{
    emit("close")
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>