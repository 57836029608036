import {ref} from "vue"
import { defineStore } from 'pinia'
import {useDialogStore} from "@/stores/dialog_list.js"
export const useRem = defineStore('rem',()=>{
    const dialog = useDialogStore()
    const client_mode = ref(1) //1PC 2移动
    const baseSize = 16
    const setRem = ()=>{
        const device = navigator.userAgent.toLowerCase();
        if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(device)) {
            //移动端
            client_mode.value = 2
            document.documentElement.style.fontSize = document.documentElement.clientWidth / 7.5 + "px"
        } else {
            // 当前页面宽度相对于 1920宽的缩放比例，可根据自己需要修改。
            const scale = document.documentElement.clientWidth / 1920
            // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
            document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px'
            client_mode.value = 1
        }
    }
    const useInit = ()=>{
        setRem()
        window.onresize = ()=> {
            setTimeout(()=>{
                dialog.dispatch("onresize")
                setRem()
            }, 100)
        }
    }
    return {
        client_mode,
        useInit
    }
})