<template>
    <div class="wallet">
        <div class="title fn24 bold">{{$t('message.wode_zhanghu')}}</div>
        <div class="panel h_center1" v-if="rem.client_mode == 1">
            <div class="info ac">
                <img src="@/assets/logo.png" alt="">
                <div class="name">{{ userInfo.username }}</div>
                <div class="btns h_center">
                    <div class="btn h_center cursor" @click="show_update=!show_update">{{$t('message.xiugai_zhanghu')}}</div>
                    <div class="btn h_center mglf cursor" @click="show_pwd=!show_pwd">{{$t('message.xiugai_mima')}}</div>
                </div>
            </div>
            <div class="account flex wrap between h_center1 F1">
                <div class="lf">
                    <div class="label fn16">{{$t('message.zhanghu_yue')}}</div>
                    <div class="money fn36 bold">{{userInfo.money}}USD</div>
                </div>
                <div class="rt flex">
                    <div class="btn h_center fn18 bold hover cursor" @click="show_rec=!show_rec">
                        <span>{{$t('message.chongzhi')}}</span>
                    </div>
                    <div class="btn h_center fn18 bold cursor" @click="show_tibi=!show_tibi">
                        <span>{{$t('message.zhuanzhang')}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="panel" v-else>
            <div class="info ac">
                <img src="@/assets/logo.png" alt="">
                <div class="name">{{ userInfo.username }}</div>
                <div class="btns h_center">
                    <div class="btn h_center cursor" @click="show_update=!show_update">{{$t('message.xiugai_zhanghu')}}</div>
                    <div class="btn h_center mglf cursor" @click="show_pwd=!show_pwd">{{$t('message.xiugai_mima')}}</div>
                </div>
            </div>
            <div class="account F1">
                <div class="lf ac">
                    <div class="label fn16">{{$t('message.zhanghu_yue')}}</div>
                    <div class="money fn36 bold">{{userInfo.money}}USD</div>
                </div>
                <div class="rt h_center" style="margin-top:0.24rem;">
                    <div class="btn h_center fn18 bold hover" style="margin-left:0;" @click="show_rec=!show_rec">
                        <span>{{$t('message.chongzhi')}}</span>
                    </div>
                    <div class="btn h_center fn18 bold cursor" @click="show_tibi=!show_tibi">
                        <span>{{$t('message.zhuanzhang')}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="list">
            <div class="tap flex wrap">
                <div class="button h_center fn18 bold cursor">
                    <span>{{$t('message.jiaoyi_jilu')}}</span>
                </div>
            </div>
            <div class="" v-if="rem.client_mode == 1">
                <div class="son" v-for="(item,index) in list" :key="index">
                    <div class="order-top fn20 wrap between h_center1">
                        <div class="order-sn h_center1">
                            <div class="h_center1">
                                <div class="type h_center" :class="{'hover':item.type==1}">{{item.type==1?$t('message.zhuanzhang'):item.type==2?$t('message.chongzhi'):$t('message.tixian')}}</div>
                                <div class="text">{{item.memo}}</div>
                            </div>
                            <span class="val rel">{{$t('message.dingdan_bianhao')}}：{{item.order_number}}</span>
                        </div>
                        <div class="status h_center1" v-if="item.type==2">
                            <span>{{item.thrid_status_html}}</span>
                            <div v-if="item.thrid_status==0" @click="showInfo(item)" class="payfor h_center cursor">{{$t('message.quzhifu')}}</div>
                        </div>
                    </div>
                    <div class="order-context h_center1">
                        <div class="lf flex h_center1 F1">
                            <img src="@/assets/61.png" class="icon" alt="">
                            <div class="context bold">
                                <div class="name fn24 val">{{item.money}}USD</div>
                                <div class="label" v-if="item.type==1 && item.to_username">{{$t('message.zhuanzhang')}}{{$t('message.gei')}}{{item.to_username}}</div>
                                <div class="label" v-if="item.type==1 && !item.to_username">{{$t('message.zhuanzhang')}}</div>
                                <div class="label" v-if="item.type==2">{{$t('message.chongzhi')}}</div>
                                <div class="label" v-if="item.type==3">{{$t('message.tixian')}}</div>
                            </div>
                        </div>
                        <div class="context bold" style="margin-left:0.32rem;">
                            <div class="name fn24 val">{{item.createtime}}</div>
                            <div class="label">{{item.type==1?$t('message.zhuanzhang'):item.type==2?$t('message.chongzhi'):$t('message.tixian')}}{{$t('message.shijian')}}</div>
                        </div>
                        <div class="context bold ar F1">
                            <div class="name fn24 val">{{Number(item.before)>Number(item.after)?'-':'+'}}USD</div>
                            <div class="label">{{$t('message.yue')}}:{{item.after}}USD</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="" v-else>
                <div class="son" v-for="(item,index) in list" :key="index">
                    <div class="order-top fn20">
                        <div class="order-sn h_center1 wrap between h_center1">
                            <div class="type h_center" :class="{'hover':item.type==1}">{{item.type==1?$t('message.zhuanzhang'):item.type==2?$t('message.chongzhi'):$t('message.tixian')}}</div>
                            <div class="text" style="margin-right:0;">{{item.memo}}</div>
                        </div>
                        <div class="flex wrap between status h_center1" v-if="item.type==2">
                            <span class="val rel">{{$t('message.dingdan_bianhao')}}：{{item.order_number}}</span>
                            <span>{{item.thrid_status_html}}</span>
                            <div v-if="item.thrid_status==0" @click="showInfo(item)" class="payfor h_center cursor">{{$t('message.quzhifu')}}</div>
                        </div>
                    </div>
                    <div class="order-context">
                        <div class="lf flex h_center1 F1">
                            <img src="@/assets/61.png" class="icon" alt="">
                            <div class="context bold">
                                <div class="name fn24 val">{{item.money}}USD</div>
                                <div class="label" v-if="item.type==1 && item.to_username">{{$t('message.zhuanzhang')}}{{$t('message.gei')}}{{item.to_username}}</div>
                                <div class="label" v-if="item.type==1 && !item.to_username">{{$t('message.zhuanzhang')}}</div>
                                <div class="label" v-if="item.type==2">{{$t('message.chongzhi')}}</div>
                                <div class="label" v-if="item.type==3">{{$t('message.tixian')}}</div>
                            </div>
                        </div>
                        <div class="flex h_center1" style="margin-top:0.2rem;">
                            <div class="context bold" style="margin-left:0.32rem;">
                                <div class="name fn24 val">{{item.createtime}}</div>
                                <div class="label">{{item.type==1?$t('message.zhuanzhang'):item.type==2?$t('message.chongzhi'):$t('message.tixian')}}{{$t('message.shijian')}}</div>
                            </div>
                            <div class="context bold ar F1">
                                <div class="name fn24 val">{{Number(item.before)>Number(item.after)?'-':'+'}}USD</div>
                                <div class="label">{{$t('message.yue')}}:{{item.after}}USD</div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            
        </div>
        <div class="flex flex-end h_center1 page" v-if="list.length!=0"><el-pagination background layout="prev, pager, next"
            :page-size="pagination.limit" :total="pagination.total" @change="turnPage" /></div>
        <!-- 提币申请&内部转账&转到我的钱包 -->
        <div class="" v-if="show_tibi"><TiBi @close="show_tibi=!show_tibi" @fresh="fresh" /></div>
        <!-- 修改账户 -->
        <div class="" v-if="show_update"><UpdateAccount @close="show_update=!show_update" /></div>
        <!-- 修改密码 -->
        <div class="" v-if="show_pwd"><UpdatePassword @close="show_pwd=!show_pwd" /></div>
        <!-- 充值 -->
        <div class="" v-if="show_rec"><Recharge @close="show_rec=!show_rec" @fresh="fresh" /></div>
        <!-- 上笔充值 -->
        <div class="" v-if="show_before_rec"><BeforeRecharge :address="address" @submit="show_rec=!show_rec" :qrcode_url="qrcode_url" :times="times" @close="show_before_rec=!show_before_rec" @fresh="fresh" /></div>
    </div>
</template>

<script setup>
import {ref,onMounted,inject} from "vue"
import { storeToRefs } from 'pinia'
import {useUserStore} from "@/stores/user.js"
const {userInfo} = storeToRefs(useUserStore())
const $axios = inject("$axios")
import { useRem } from "@/stores/rem.js"
const rem = useRem()

import TiBi from "@/components/center/MyAccount/TiBi/TiBi"
const show_tibi = ref(false)

import UpdateAccount from "@/components/center/MyAccount/UpdateAccount/UpdateAccount"
const show_update = ref(false)

import UpdatePassword from "@/components/center/MyAccount/UpdatePassword/UpdatePassword"
const show_pwd = ref(false)

import Recharge from "@/components/center/MyAccount/Recharge/Recharge"
const show_rec = ref(false)

import BeforeRecharge from "@/components/center/MyAccount/BeforeRecharge/BeforeRecharge"
const show_before_rec = ref(false)

import page from "@/api/page.js"
const { url, list, method, query, turnPage, pagination, isLoading, handleSizeChange } = page($axios)
onMounted(() => {
    url.value = "/api/user/usermoneylog"
    method.value="get"
    turnPage(pagination.page)
    // setTimeout(() => {
    //     // alert(111)
    //     // socketsj.ws.send('makfx&data+31171064')
    // }, 1000)
})

const fresh =()=>{
    pagination.page=1
    turnPage(pagination.page)
}

const address = ref("")
const qrcode_url = ref("")
const times = ref("")
const showInfo = (info)=>{
    address.value = info.thrid_address
    qrcode_url.value = info.thrid_url
    times.value = info.thrid_time
    show_before_rec.value = true
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>