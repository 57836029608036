import {ref,reactive} from "vue"
import { defineStore } from 'pinia'
import axios from '@/utils/http.js'
export const usePublicStore = defineStore('filter', () => {
    const public_info = reactive({})
    const getContext = ()=>{
        axios.get("/api/index/getConf").then(
            res=>{
                Object.assign(public_info,res.data)
            },
            err=>console.log(err)
        )
    }
    return {
        getContext,public_info
    }
})