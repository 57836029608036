<template>
    <div class="record fix h_center">
        <div class="content">
            <div class="title flex wrap between h_center1">
                <span class="fn24 bold">{{$t('message.jiaoyi')}}</span>
                <img src="@/assets/33.png" @click="close" class="icon cursor" alt="">
            </div>
            <div class="box">
                <el-table :data="socket.list" height="400" :header-row-class-name="tableHeaderClassName" :row-class-name="tableRowClassName" style="width: 100%">
                    <el-table-column prop="订单号" align="center" :label="$t('message.dingdanhao')" />
                    <el-table-column prop="订单盈亏" align="center" :label="$t('message.dingdan_yinkui')" />
                    <el-table-column prop="手数" align="center" :label="$t('message.shou_shu')" />
                    <el-table-column prop="开仓时间" width="150" align="center" :label="$t('message.kaicang_shijian')" />
                    <el-table-column prop="开仓价格" align="center" :label="$t('message.kaicang_jiage')" />
                    <el-table-column prop="交易货币" align="center" :label="$t('message.jiaoyi_huobi')" />
                    <el-table-column prop="交易类型" align="center" :label="$t('message.jiaoyi_leixing')" >
                      <template #default="scope">
                          <div>{{ scope.row['交易类型']==1?'SELL':'BUY' }}</div>
                      </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- <div class="h_center page"><el-pagination background layout="prev, pager, next" :total="1000" /></div> -->
        </div>
    </div>
</template>

<script setup>
import {ref} from "vue"
const tableRowClassName = ({row,rowIndex})=>{
    if ((rowIndex+1)%2==0)return 'hover fn20'
    return "fn20"
}
const tableHeaderClassName = ({})=>{
    return "fn20"
}

import { useSocket } from "@/stores/socket"
const socket = useSocket()
const tableData = ref([
  {
    date: '2016-05-03',
    name: 'Tom',
    address: 'No. 189,',
  },
  {
    date: '2016-05-02',
    name: 'Tom',
    address: 'No. 189,',
  },
  {
    date: '2016-05-04',
    name: 'Tom',
    address: 'No. 189,',
  },
  {
    date: '2016-05-01',
    name: 'Tom',
    address: 'No. 189,',
  },
])
const emit = defineEmits(['close'])
const close = ()=>{
    emit("close")
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>