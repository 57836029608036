<template>
    <div class="header h_center1 flex wrap between" ref="head_height" :class="{'fix':rem.client_mode==2}">
        <img src="@/assets/2.png" alt="" class="logo cursor" @click="home">
        <div class="rt">
            <!-- <div class="lang h_center">
                <el-dropdown @command="handleLocal">
                    <div class="view h_center fn20 cursor" style="outline: none;">
                        <img src="@/assets/3.png" alt="">
                        <span>{{$t('message.yuyan')}}</span>
                    </div>
                    <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item v-for="(item,index) in options" :command="item.value" :key="index"><span class="fn20">{{item.label}}</span></el-dropdown-item>
                    </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div> -->
            <div class="user h_center1">
                <div class="view-box rel">
                    <div class="view h_center fn20 cursor" @click="show_select=!show_select" style="outline: none;">
                        <img src="@/assets/3.png" alt="">
                        <span>{{language.name}}</span>
                    </div>
                    <div class="opts abs" v-if="show_select">
                        <div class="child h_center cursor" v-for="(item,index) in language.options" @click="handleLocal(item.value)" :key="index">{{ item.label }}</div>
                    </div>
                </div>
                <div class="login h_center fn20 bold cursor" v-if="!userInfo.user_id" @click="login">{{$t('message.login')}}</div>
                <div class="user-box h_center fn20 bold cursor" @click="center"  v-else>
                    <span class="price">{{$t('message.yue')}}：{{userInfo.money}}USD</span>
                    <img src="@/assets/45.png" alt="">
                    <span>hi~{{ userInfo.username }}</span>
                </div>
                <div class="menu h_center" @click="toggle" v-if="rem.client_mode==2 && props.show"><img src="@/assets/63.png" class="icon" alt=""></div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref,onMounted} from "vue"
import {useRouter} from "vue-router"

import {useRem} from "@/stores/rem.js"
const rem = useRem()

import { storeToRefs } from 'pinia'
import {useUserStore} from "@/stores/user.js"
const {userInfo} = storeToRefs(useUserStore())

const value = ref("")
const head_height = ref(null)

const router = useRouter()
const login = ()=>{
    router.push({
        path:"/login"
    })
}
const center = ()=>{
    router.push({
        path:"/center"
    })
}
const home = ()=>{
    router.push({
        path:"/"
    })
}
const emit = defineEmits(['getHeight','toggle'])
onMounted(()=>{
    emit('getHeight',head_height.value.offsetHeight)
})
const toggle = ()=>{
    emit('toggle')
}
import {useLanguage} from "@/stores/language.js"
const language = useLanguage()
const show_select = ref(false)
const handleLocal = (value)=>{
    language.handleLocal(value)
    show_select.value = false
}

const props = defineProps({
    show:Boolean
})
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>