const calcDescartes = (array)=>{
    if (array.length < 2) return array[0] || [];
    return [].reduce.call(array,(col,set)=> {
        const res = []
        col.map(c=> {
            set.map(s=> {
                const t = [].concat(Array.isArray(c) ? c : [c]);
                t.push(s)
                res.push(t)
            })
        })
        return res
    })
}
export const baseRequest= /localhost/.test(window.location.host) ? '/api' : `https://${window.location.hostname}`
export const imgUrl= `https://${window.location.hostname}`