export const takeChatWebSoket=(callback,url)=>{
	let ws;
	if(window.WebSocket){
		ws = new WebSocket(url);
		// ws.close = false
		console.log("正在建立链接...");
	}else{
		alert('你的设备不具备聊天功能');
	}
	ws.onopen=function(){
		console.log("连接成功...");
		callback(ws);
	}
	ws.onerror=function(){
		console.log("聊天连接发生错误!");
		return;
	}
	ws.onclose=function(){
		// ws.close = false
		// console.log("连接已断开...3秒后自动重连...")
		// let num=0;
		// let timer=setInterval(function(){
		// 	num++;
		// 	console.log(num+"...")
		// 	if(num==3){
		// 		takeChatWebSoket(callback,url)
		// 		clearInterval(timer)
		// 		timer = null
		// 	}
		// },1000)
	}
}