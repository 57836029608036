<template>
    <div class="update fix h_center">
        <div class="form rel">
            <img src="@/assets/33.png" class="close abs" @click="close" alt="">
            <div class="item">
                <div class="label fn24 bold">{{$t('message.kaihu_youxiang')}}</div>
                <input type="text" :placeholder="$t('message.shuru_kaihu_youxiang')" v-model="email" class="input fn20" >
            </div>
            <div class="submit h_center fn20 bold" @click="handle">{{$t('message.faqi_kaihu_yaoqing')}}</div>
        </div>
    </div>
</template>

<script setup>
import {ref,inject} from "vue"
const emits = defineEmits(['close'])
const close = ()=>{
    emits('close')
}

import { ElLoading, ElMessage } from 'element-plus'
const $axios = inject("$axios")
const email = ref("")
const handle = async ()=>{
    const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    try {
        const result = await $axios.post("/api/ems/commonCode",{email:email.value,event:"register"})
        loading.close() 
        ElMessage.success(result.msg?result.msg:'发送成功')
    } catch (e) {
        loading.close() 
        console.log(e) 
    }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>