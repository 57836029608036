<template>
    <div class="overview">
        <!-- <Service /> -->
        <div class="title fn24 bold">{{$t('message.gailan')}}</div>
        <!-- <div class="account flex wrap between h_center1 fn18" v-html="company_desc"></div> -->
        <div class="panel flex wrap h_center1">
            <div class="child h_center">
                <div>
                    <div class="h_center1">
                    <img src="@/assets/58.png" class="icon" alt=""> 
                        <span>{{$t('message.zhanghu_shuliang')}}</span>
                    </div>
                    <div class="val fn36 bold">3</div>
                </div>
            </div>
            <div class="child h_center">
                <div>
                    <div class="h_center1">
                    <img src="@/assets/58.png" class="icon" alt=""> 
                        <span>{{$t('message.wode_tuoguan')}}</span>
                    </div>
                    <div class="val fn36 bold">3</div>
                </div>
            </div>
            <div class="child h_center">
                <div>
                    <div class="h_center1">
                    <img src="@/assets/58.png" class="icon" alt=""> 
                        <span>{{$t('message.zi_zhanghu_shuliang')}}</span>
                    </div>
                    <div class="val fn36 bold">3</div>
                </div>
            </div>
        </div>
        <div class="list">
            <div class="title fn24 bold">{{$t('message.gonggao_liebiao')}}</div>
            <div class="son flex h_center1 fn18" @click="showDetail(item.content,item.title)" v-for="(item,index) in list" :key="index">
                <img src="@/assets/logo.png" alt="" class="photo">
                <div class="context rel F1">
                    <div class="name bold">{{item.title}}</div>
                    <div class="text nowrap2">{{item.title}}{{item.title}}{{item.title}}{{item.title}}{{item.title}}</div>
                    <div class="bottom abs h_center1">
                        <div class="date">2024-10-10</div>
                        <div class="views h_center1">
                            <span>556</span>
                            <img src="@/assets/60.png" alt="">
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <!-- <div class="h_center page"><el-pagination background layout="prev, pager, next"
                :page-size="pagination.limit" :total="pagination.total" /></div> -->
        <div class="details fix" v-if="show">
            <div class="head h_center fn24 rel bold">
                <span>{{title}}</span>
                <img src="@/assets/33.png" @click="show=!show" class="close abs" alt="">
            </div>
            <div class="context fn20" v-html="content"></div>
        </div>
    </div>
</template>

<script setup>
import {ref,onMounted,inject} from "vue"
import Service from "@/components/center/Service/Service"
import { storeToRefs } from 'pinia'
import {useUserStore} from "@/stores/user.js"
const {userInfo} = storeToRefs(useUserStore())
const $axios = inject("$axios")

// import page from "@/api/page.js"
// const { url, list, method, query, turnPage, pagination, isLoading, handleSizeChange } = page($axios)
onMounted(() => {
    // pagination.total = 20
    // setTimeout(() => {
    //     // alert(111)
    //     // socketsj.ws.send('makfx&data+31171064')
    // }, 1000)
    init()
})

const list = ref([])
const company_desc = ref("")
const init = ()=>{
    $axios.get("/api/index/article?cate_id=1").then(
        res=>{
            list.value = res.data
        },
        err=>console.log(err)
    )
    $axios.get("/api/index/getConf").then(
        res=>{
            company_desc.value = res.data.company_desc
        },
        err=>console.log(err)
    )
}
const content = ref("")
const title = ref("")
const show = ref(false)
const showDetail = (_content,_title)=>{
    content.value = _content
    title.value = _title
    show.value = !show.value
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>