const zh = {
    yuyan:"語言",
    login:"登錄",
    yue:"餘額",
    zhanghao_tuoguan:"賬號託管",
    shuru_fuwuqi_dizhi:"輸入服務器地址",
    shuru_mt4_zhanghao:"輸入MT4賬號",
    shuru_mt4_mima:"輸入MT4密碼",
    xuanze_celue:"選擇策略",
    tijiao:"提交",
    wode_zhanghu:"我的賬戶",
    xiugai_zhanghu:"修改賬戶",
    xiugai_mima:"修改密碼",
    zhanghu_yue:"賬戶餘額",
    zhanghu_shuliang:"賬戶數量",
    chongzhi:"充值",
    zhuanzhang:"轉賬",
    zhuanzhang_jine:"轉賬金額",
    shuru_zhuanzhang_jine:"輸入轉賬金額",
    jiaoyi_jilu:"交易記錄",
    tixian:"提現",
    dingdan_bianhao:"訂單編號",
    quzhifu:"去支付",
    gei:"給",
    shijian:"時間",
    yanzheng_youxiang_dizhi:"驗證郵箱地址",
    shuru_yanzhengma:"輸入驗證碼",
    fasong_yanzhengma:"發送驗證碼",
    yuan_pingtai_mima:"原平台密碼",
    shuru_pingtai_mima:"輸入平台密碼",
    xin_pingtai_mima:"新平台密碼",
    yanzheng_yuan_youxiang_dizhi:"驗證原郵箱地址",
    yanzheng_xin_youxiang_dizhi:"驗證新郵箱地址",
    shuru_youxiang_dizhi:"輸入郵箱地址",
    shuru_xin_youxiang_dizhi:"輸入新郵箱地址",
    shezhi_xin_youxiang:"設置新郵箱",
    shuru_xin_youxiang_yanzhengma:"輸入新郵箱驗證碼",
    neibu_zhuanzhang:"內部轉賬",
    zhuandao_qianbao:"轉到錢包",
    tibi:"提幣",
    youxiang_yanzheng:"郵箱驗證",
    tibi_jine:"提幣金額",
    chongzhi_jine:"充值金額",
    shuru_chongzhi_jine:"輸入充值金額",
    shuru_tibi_jine:"輸入提幣金額",
    duifang_youxiang:"對方郵箱",
    shuru_duifang_youxiang:"輸入對方郵箱",
    tixing:"提醒",
    mashang_jihuo:"您還沒有激活錢包，是否馬上激活？",
    liji_jihuo:"立即激活",
    qingzai:"請在",
    shi:"時",
    fen:"分",
    miao:"秒",
    qian:"前",
    wancheng_shixiao:"完成充值，否則將失效",
    shiqu_jihui:"完成開戶，否則將失去本次機會！",
    benci_shixiao:"本次開戶邀請已失效",
    yishixiao_chongzhi:"二維碼已失效，請重新充值",
    shuaxin:"刷新",
    chongzhi_chenggong:"充值成功！",
    guanbi:"關閉",
    wode_tuandui:"我的團隊",
    tuandui_renshu:"團隊人數",
    kaihu:"開戶",
    kaihu_youxiang:"開戶郵箱",
    shuru_kaihu_youxiang:"輸入開戶郵箱",
    tuandui_liebiao:"團隊列表",
    zhanghao:"賬號",
    kaihu_riqi:"開戶日期",
    chakan:"查看",
    chakan_zhanghu_xinxi:"查看賬戶信息",
    faqi_kaihu_yaoqing:"發起開戶邀請",
    gailan:"概覽",
    wode_tuoguan:"我的託管",
    zi_zhanghu_shuliang:"子賬戶數量",
    gonggao_liebiao:"公告列表",
    jiaoyi:"交易",
    dingdanhao:"訂單號",
    dingdan_yinkui:"訂單盈虧",
    shou_shu:"手數",
    kaicang_shijian:"開倉時間",
    kaicang_jiage:"開倉價格",
    jiaoyi_huobi:"交易貨幣",
    jiaoyi_leixing:"交易類型",
    lianxi_kefu:"聯係客服",
    jiaoyi_celue:"交易策略",
    zhanghu_tuoguan:"賬戶託管",
    tuoguan_zhong:"託管中",
    jiechu_tuoguan:"解除託管",
    jinyong:"禁用",
    zuixin_shouyi:"最新收益",
    leiji_shouyi:"累計收益",
    nianhua:"7日年化",
    chakan_jiaoyi:"查看交易",
    duihuan_shuoming:"兌換說明",
    wode_qianbao:"我的錢包",
    wode_yue:"我的餘額",
    qianbao_dizhi:"錢包地址",
    qianbao_shuoming:"錢包說明",
    jihuo_qianbao:"激活錢包",
    duihuan:"兌換",
    huanying_nin:"歡迎您",
    qingshuru_mima:"請輸入密碼",
    zhanghu_mima:"賬戶密碼",
    queren_mima:"確認密碼",
    qing_queren_mima:"請再次確認密碼",
    queren_kaihu:"確認開戶",
    qingshuru_zhanghu:"請輸入賬戶名稱",
    tuoguan_fuwu:"托管服務",
    yingli_moxing:"盈利模型",
    weilai_jihua:"未來計劃",
    home_area1:"致力於為外匯投資者提供一個具有高穩定收益、低風險的交易策略",
    home_area2:"當前將采用封閉式運營模式。由於市場混亂，makfx.com暫時不能自主註冊，當跨平台的兼容託管服務打到我們的期望水平，將開放makfx.com的封閉運營",
    home_area3:"擁有自主研發的託管監控為每個託管帳戶提供實时交易數據，並有專業團隊5X24小時即時監控，為您的託管交易保駕護航！",
    home_area4:"您僅需支付極少量的託管費用即可獲取我們的完整支持和服務，我們不會參與您的利潤分配，相反，我們將會承擔您止損需要託管週期的一切託管費用！",
    home_area5:"交易託管服務器均採用主從模式，擁有99.99%高可用的穩定交易環境。 我們在全球各大機房均架設了託管服務器，為不同零售交易平臺提供穩定交易支持！",
    home_area6:"我們將持續對MakFX EA程式碼進行更新同時，也將更新更多的風險模型供您選擇不同的風險模型都是基於MakFX覈心，僅調控了不同並且合適的盈利能力和風險水准！",
    home_area7:"採用穩健的設定方案，為您提供年化50%的預期收益預期月收益：4%-5%預期年止損次數：1 / 15%注：幾乎沒有止損的情况（極特殊的行情除外，如黑天鹅事件）",
    home_area8:"採用正常的設定方案，為您提供年化90%的預期收益預期月收益：7%-10%預期年止損次數：1 / 15%注：很多年份都不會出現止損情况",
    home_area9:"低門檻是策略成功的標誌",
    home_area10:"當前MakFX策略已經將最低入門資金控制到了2000USD，策略的盈利能力與風險水准取決於杠杆大小和持倉大小，未來我們將進一步優化策略實現更低門檻提高策略的低風險特性",
    home_area11:"MakFX開放平臺運營",
    home_area12:"當前將採用封閉式運營模式。 由於市場混亂，makfx.com暫時不能自主注册，當跨平臺的相容託管服務達到我們的期望水准，將開放makfx.com的封閉運營",
    home_area13:"可控的EA程式合作模式",
    home_area14:"MakFX EA程式碼引用了較多的dll動態連結程式庫，我們也正在綜合大部分功能，並致力於推出一款線上的EA程式來應對不願意託管帳戶的用戶，未來您可以下載我們的EA程式使用makfx.com平臺帳號進行登入，以便在自己的生產環境下進行自動交易",
    text_area:"MakFX致力於為外匯投資者提供一個具有高穩定收益、低風險的交易策略。MakFX核心交易策略中，以EURUSD(歐元兌美元)和(美元兌瑞士法郎)為核心交易對，由於瑞士為中立國家，瑞士法郎貨幣匯率穩定性較強。EURUSD和USDCHF的匯率波動在大部分時間都是反向而行（並不是絕對反向以及絕對對稱），MakFX策略會實時計算長達10年的歷史匯率值，以特殊算法計算扭差進行交易。",
    text_area2:"策略將引用一定的加倉，但可控性非常高，基礎加倉僅為1.1倍率，最大不會超過1.3倍率，由於國家政策最終會維穩貨幣的性質，將促使扭差回歸一個趨於正常的值實現盈利！在必要的時候，我們將會在交易過程中增加一個貨幣對EURCHF（歐元兌瑞士法郎）進行匯率聯動以應對大級別的風險，策略單次最大止損線为30%，一一般會設置為15%-20%在盈利能力上，MakF將提供多種模型，不同模型將不同的抗風險和盈利能力，適用於各種類型的投資者，當前有4-5%超穩健型、7-10%普及型、15%-20%激進型。"
}

export default zh