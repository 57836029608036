import {ref,reactive} from "vue"
import { defineStore } from 'pinia'
import axios from '@/utils/http.js'

export const useUserStore = defineStore('user', () => {
    const storage_key =ref(localStorage.getItem("storage_key")?localStorage.getItem("storage_key"):"sessionStorage")
    const token = ref(window[storage_key.value].getItem("token")?window[storage_key.value].getItem("token"):"")
    const userInfo = reactive({})
    const timer = ref(null)
    const setAppUserToken =(val)=>{
        token.value = val
        window[storage_key.value].setItem("token",val)
    }
    const logout = ()=>{
        window[storage_key.value].removeItem("token")
        token.value = ""
    }
    const setStorageKey = (val)=>{
        storage_key.value = val
        localStorage.setItem("storage_key",val)
    }
    const init = async()=>{
        clearTimer()
        if(token.value)getUserInfo()
        // timer.value=setTimeout(()=>{
        //     if(!timer.value)return
        //     init()
        // },5000)
    }
    const clearTimer = ()=>{
        if(timer.value){
            clearInterval(timer.value)
            timer.value = ""
        }
    }
    const getUserInfo = async ()=>{
        return new Promise(async (resolve,reject)=>{
            const result = await axios.post("/api/user/userinfo",{})
            Object.assign(userInfo,result.data)
            const wallet = await axios.post("/api/account/my_wallet",{})
            Object.assign(userInfo,wallet.data)
            resolve()
        })
    }
    return { storage_key,token,userInfo, setStorageKey,logout,setAppUserToken,getUserInfo,init }
})