<template>
    <div class="center">
        <div class="">
            <Header @getHeight="getHeight" @toggle="action=!action" show />
        </div>
        <div class="out-box rel">
            <div ref="vantaRef" class="canvas" :style="rem.client_mode == 2 ? `height:${body_height - top}px` : ''"></div>
            <div class="body abs" 
                :class="{ 'abs': rem.client_mode == 1, 'fix': rem.client_mode == 2 }"
                :style="rem.client_mode == 2 ? `top:${top}px;height:${body_height - top}px` : ''"
                >
                <div class="body-box flex">
                    <div class="lfbar ac" v-if="rem.client_mode == 1">
                        <div class="group fn14 bold">
                            <div class="child h_center ac cursor rel" :class="{'hover':tab==3}" @click="tab = 3">
                                <div class="">
                                    <img src="@/assets/51.png" class="icon" alt="">
                                    <div class="text">{{$t('message.gailan')}}</div>
                                    <div class="line abs"></div>
                                </div>
                            </div>
                            <div class="child h_center ac cursor rel" :class="{'hover':tab==4}" @click="tab = 4">
                                <div class="">
                                    <img src="@/assets/52.png" class="icon" alt="">
                                    <div class="text">{{$t('message.wode_zhanghu')}}</div>
                                    <div class="line abs"></div>
                                </div>
                            </div>
                            <div class="child h_center ac cursor rel" :class="{'hover':tab==1}" @click="tab = 1">
                                <div class="">
                                    <img src="@/assets/53.png" class="icon" alt="">
                                    <div class="text">{{$t('message.wode_tuoguan')}}</div>
                                    <div class="line abs"></div>
                                </div>
                            </div>
                            <div class="child h_center ac cursor rel" :class="{'hover':tab==5}" @click="tab = 5">
                                <div class="">
                                    <img src="@/assets/54.png" class="icon" alt="">
                                    <div class="text">{{$t('message.jiaoyi_celue')}}</div>
                                    <div class="line abs"></div>
                                </div>
                            </div>
                            <div class="child h_center ac cursor rel" :class="{'hover':tab==2}" @click="tab = 2" v-if="userInfo.is_wallet==1">
                                <div class="">
                                    <img src="@/assets/55.png" class="icon" alt="">
                                    <div class="text">{{$t('message.wode_qianbao')}}</div>
                                    <div class="line abs"></div>
                                </div>
                            </div>
                            <div class="child h_center ac cursor rel" @click="jihuo" v-else>
                                <div class="">
                                    <img src="@/assets/55.png" class="icon" alt="">
                                    <div class="text">{{$t('message.jihuo_qianbao')}}</div>
                                </div>
                            </div>
                            <div class="child h_center ac cursor rel" :class="{'hover':tab==6}" @click="tab = 6">
                                <div class="">
                                    <img src="@/assets/56.png" class="icon" alt="">
                                    <div class="text">{{$t('message.wode_tuandui')}}</div>
                                    <div class="line abs"></div>
                                </div>
                            </div>
                            <div class="child h_center ac cursor rel" :class="{'hover':tab==7}" @click="tab = 7">
                                <div class="">
                                    <img src="@/assets/57.png" class="icon" alt="">
                                    <div class="text">USDT{{$t('message.duihuan')}}</div>
                                    <div class="line abs"></div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="single h_center cursor">
                            <div class="">
                                <img src="@/assets/30.png" class="icon" alt="">
                                <div class="text">USDT兑换</div>
                            </div>
                        </div> -->
                    </div>
                    <div class="section F1">
                        <!-- <div class="app flex wrap between h_center1 rel" v-show="show_other">
                            <div class="lf flex h_center1 cursor">
                                <img src="@/assets/31.png" alt="">
                                <div class="context">
                                    <div class="text fn30 bold">联系客服</div>
                                </div>
                            </div>
                            <div class="close abs cursor" @click="show_other = !show_other"><img src="@/assets/33.png" alt=""></div>
                        </div> -->
                        <div class="navbars fix" :class="{'ani':action}" v-if="rem.client_mode == 2">
                            <div class="group fn24 bold ac">
                                <div class="child ac cursor" @click="tab = 3">
                                    <img src="@/assets/51.png" class="icon" alt="">
                                    <div class="text">{{$t('message.gailan')}}</div>
                                </div>
                                <div class="child ac cursor" @click="tab = 4">
                                    <img src="@/assets/52.png" class="icon" alt="">
                                    <div class="text">{{$t('message.wode_zhanghu')}}</div>
                                </div>
                                <div class="child ac cursor" @click="tab = 1">
                                    <img src="@/assets/53.png" class="icon" alt="">
                                    <div class="text">{{$t('message.wode_tuoguan')}}</div>
                                </div>
                                <div class="child ac cursor" @click="tab = 5">
                                    <img src="@/assets/54.png" class="icon" alt="">
                                    <div class="text">{{$t('message.jiaoyi_celue')}}</div>
                                </div>
                                <div class="child ac cursor" @click="tab = 2" v-if="userInfo.is_wallet==1">
                                    <img src="@/assets/55.png" class="icon" alt="">
                                    <div class="text">{{$t('message.wode_qianbao')}}</div>
                                </div>
                                <div class="child ac cursor" @click="jihuo" v-else>
                                    <img src="@/assets/55.png" class="icon" alt="">
                                    <div class="text">{{$t('message.jihuo_qianbao')}}</div>
                                </div>
                                <div class="child ac cursor" @click="tab = 6">
                                    <img src="@/assets/56.png" class="icon" alt="">
                                    <div class="text">{{$t('message.wode_tuandui')}}</div>
                                </div>
                                <div class="child ac cursor" @click="tab = 7">
                                    <img src="@/assets/57.png" class="icon" alt="">
                                    <div class="text">USDT{{$t('message.duihuan')}}</div>
                                </div>
                            </div>
                        </div>
                        <!-- 我的托管 -->
                        <div class="" v-show="tab == 1">
                            <TuoGuan />
                        </div>

                        <!-- 我的钱包 -->
                        <div class="" v-if="tab == 2">
                            <Wallet />
                        </div>

                        <!-- 交易记录 -->
                        <div class="" v-if="show">
                            <Record @close="show = !show" />
                        </div>

                        <!-- 概览 -->
                        <div class="" v-show="tab == 3">
                            <Overview />
                        </div>
                        <!-- 我的账户 -->
                        <div class="" v-if="tab == 4">
                            <MyAccount />
                        </div>
                        <!-- 交易策略 -->
                        <div class="" v-if="tab == 5">
                            <TradingStrategy />
                        </div>
                        <!-- 我的团队 -->
                        <div class="" v-if="tab == 6">
                            <MyTeam />
                        </div>
                        <!-- USDT兑换 -->
                        <div class="" v-if="tab == 7">
                            <USDTExchange />
                        </div>
                    </div>
                </div>
                <div class="shuoming" :class="{ 'fn14': rem.client_mode == 1, 'fn20': rem.client_mode == 2 }">
                    <div class="">{{$t('message.text_area')}}
                    </div><br>
                    <div class="">{{$t('message.text_area2')}}</div>
                </div>
            </div>
            
        </div>


    </div>
</template>

<script setup>
import Header from "@/components/Header/Header"
import TuoGuan from "@/components/center/TuoGuan/TuoGuan"
import Wallet from "@/components/center/Wallet/Wallet"
import Overview from "@/components/center/Overview/Overview"
import MyAccount from "@/components/center/MyAccount/MyAccount"
import TradingStrategy from "@/components/center/TradingStrategy/TradingStrategy"
import MyTeam from "@/components/center/MyTeam/MyTeam"
import Record from "@/components/center/Record/Record"
import USDTExchange from "@/components/center/USDTExchange/USDTExchange"
import * as THREE from "three"
import NET from "vanta/src/vanta.net"
import { ref, onMounted, onUnmounted,inject } from "vue"
import { storeToRefs } from 'pinia'
import { useRectStore } from "@/stores/rect.js"
import { useUserStore } from "@/stores/user.js"

import { useRem } from "@/stores/rem.js"
const rem = useRem()


const { userInfo,init } = storeToRefs(useUserStore())
const tab = ref(5)
const show = ref(false)

const action = ref(false)

const show_other = ref(true)

const vantaRef = ref(null)
let vantaEffect = ""

onUnmounted(() => {
    if (vantaEffect) vantaEffect.destroy()
})
const top = ref(0)
const { body_height } = storeToRefs(useRectStore())
const getHeight = (val) => {
    top.value = val
    vantaEffect = NET({
        el: vantaRef.value,
        THREE: THREE,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 200.0,
        minWidth: 200.0,
        color: 0x43F9FF,//基准颜色
        backgroundColor: 0x1D1B1B,//背景颜色 需十进制
        backgroundAlpha: 0.00,
        amplitudeFactor: 1,//振幅因子
        xOffset: 0.3,//移动到X轴
        yOffset: 0,//移动到Y轴
        size: 1.5,//大小
    })
}
import { useRouter } from 'vue-router'
const router = useRouter()
const goHome = ()=>{
    router.replace({ name: 'Home'})
}
import { ElLoading,ElMessage } from 'element-plus'
const $axios = inject("$axios")
const jihuo = async ()=>{
    const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    try {
        const result = await $axios.post("/api/user/wallet",{})
        loading.close()
        ElMessage.success(result.msg?result.msg:'激活成功')
        init()
    } catch (e) {
        loading.close() 
        console.log(e) 
    }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>