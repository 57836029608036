<template>
    <div class="login h_center">
        <div class="">
            <div class="title h_center1 fn36 bold" v-if="rem.client_mode == 1">
                <img src="@/assets/2.png" class="logo" alt="">
                <span>{{$t('message.huanying_nin')}}</span>
            </div>
            <div class="section" :class="{ 'flex wrap between h_center1': rem.client_mode == 1 }">
                <div class="box h_center rel">
                    <img src="@/assets/25.png" alt="">
                    <div class="title h_center bold abs" v-if="rem.client_mode == 2"
                        :class="{ 'fn48': rem.client_mode == 2, 'fn36': rem.client_mode == 1 }">
                        <img src="@/assets/2.png" class="logo" alt="">
                        <span>{{$t('message.huanying_nin')}}</span>
                    </div>
                </div>
                <div class="form">
                    <div class="h_center">
                        <div class="name rel ac">
                            <span class="fn24 bold rel" style="z-index:9;">{{$t('message.login')}}</span>
                            <div class="line abs"></div>
                        </div>
                    </div>
                    <el-form :model="form" ref="formRef" :rules="rules" label-position="top" label-width="120px">
                        <el-form-item label="账户名称" prop="account" class="input-box">
                            <el-input v-model="form.account" :placeholder="$t('message.qingshuru_zhanghu')" />
                        </el-form-item>
                        <el-form-item label="账户密码" prop="password" class="input-box">
                            <div class="box1 rel" style="width:100%;" :style="rem.client_mode == 2 ? 'margin-top:-0.2rem;' : ''">
                                <el-input v-model="form.password" :type="type" :placeholder="$t('message.qingshuru_mima')" />
                                <div class="switch abs h_center cursor" @click="type = type == 'text' ? 'password' : 'text'">
                                    <img src="@/assets/26.png" alt="" v-if="type == 'password'">
                                    <img src="@/assets/26_1.png" alt="" v-if="type == 'text'">
                                </div>
                            </div>
                        </el-form-item>
                    </el-form>
                    <div class="submit h_center fn24 bold cursor" @click="submit">{{$t('message.login')}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ElLoading, ElMessage } from 'element-plus'
import { reactive, inject, ref } from "vue"
import { useUserStore } from "@/stores/user.js"
import { useRouter } from "vue-router"
import { useRem } from "@/stores/rem.js"
const rem = useRem()
const formRef = ref(null)
const form = reactive({
    account: "",
    password: "",
})
const type = ref('password')
const rules = reactive({
    account: [
        { required: true, message: '请输入账户名称', trigger: 'blur' },
    ],
    password: [
        { required: true, message: '请输入密码', trigger: 'blur' },
    ]
})

const $axios = inject("$axios")
const user = useUserStore()
const router = useRouter()
const submit = () => {
    let loading = null
    if (rem.client_mode == 1) {
        loading = ElLoading.service({
            lock: true,
            text: 'Loading',
            background: 'rgba(0, 0, 0, 0.7)',
        })
    }
    formRef.value.validate(async (valid) => {
        if (valid) {
            try {
                const result = await $axios.post("/api/user/login", form)
                user.setAppUserToken(result.data.userinfo.token)
                user.getUserInfo()
                router.replace({ path: "/center" })
            } catch (e) { console.log(e) }
        }
    })
    if (rem.client_mode == 1) {
        loading.close()
    }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>