<template>
    <div class="my-team">
        <!-- <Service /> -->
        <div class="title fn24 bold">{{$t('message.wode_tuandui')}}</div>
        <div class="account flex wrap between h_center1">
            <div class="lf h_center">
                <img src="@/assets/58.png" alt="">
                <div class="money h_center">{{$t('message.tuandui_renshu')}}<span class="fn36 bold">{{pagination.total}}</span></div>
            </div>
            <div class="rt flex">
                <div class="btn h_center fn18 bold hover cursor" @click="show_open=!show_open">
                    <span>{{$t('message.kaihu')}}</span>
                </div>
            </div>
        </div>
        <div class="list">
            <div class="title fn24 bold">{{$t('message.tuandui_liebiao')}}</div>
            <div class="" v-if="rem.client_mode == 1">
                <div class="son flex h_center1 fn18" v-for="(item,index) in list" :key="index" @click="info(item.id)">
                    <div class="F1 h_center1">
                        <img src="@/assets/logo.png" class="photo" alt="">
                        <span class="text">{{$t('message.zhanghao')}}：{{item.email}}</span>
                    </div>
                    <span class="text F1">{{$t('message.kaihu_riqi')}}：{{trans_date(item.createtime)}}</span>
                    <div class="h_center rt">
                        <span>{{$t('message.chakan')}}</span>
                        <img src="@/assets/62.png" alt="">
                    </div>
                </div>
            </div>
            <div class="" v-else>
                <div class="son fn18" v-for="(item,index) in list" :key="index" @click="info(item.id)">
                    <div class="h_center1">
                        <img src="@/assets/logo.png" class="photo" alt="">
                        <span class="text">{{$t('message.zhanghao')}}：{{item.email}}</span>
                    </div>
                    <div class="h_center1" style="margin-top:0.24rem;">
                        <span class="text F1">{{$t('message.kaihu_riqi')}}：{{trans_date(item.createtime)}}</span>
                        <div class="h_center rt">
                            <span>{{$t('message.chakan')}}</span>
                            <img src="@/assets/62.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-end h_center1 page" v-if="list.length!=0"><el-pagination background layout="prev, pager, next"
                :page-size="pagination.limit" :total="pagination.total" /></div>
        <!-- 开户 -->
        <div class="" v-show="show_open">
            <OpenAccount @close="show_open=!show_open" />
        </div>
        <!-- 查看账户信息 -->
        <div class="" v-if="show_look">
            <LookInfo @close="show_look=!show_look" :user_id="user_id" />
        </div>
    </div>
</template>

<script setup>
import moment from "moment"
import {ref,inject,onMounted} from "vue"
import Service from "@/components/center/Service/Service"
import OpenAccount from "@/components/center/MyTeam/OpenAccount/OpenAccount"
import LookInfo from "@/components/center/MyTeam/LookInfo/LookInfo"
import { storeToRefs } from 'pinia'
import {useUserStore} from "@/stores/user.js"
const {userInfo} = storeToRefs(useUserStore())

const show_open = ref(false)
const show_look = ref(false)
const $axios = inject("$axios")
import page from "@/api/page.js"
const { url, list, method, query, turnPage, pagination, isLoading, handleSizeChange } = page($axios)
onMounted(() => {
    url.value = "/api/user/team"
    turnPage(pagination.page)
    // setTimeout(() => {
    //     // alert(111)
    //     // socketsj.ws.send('makfx&data+31171064')
    // }, 1000)
})

const trans_date=(seconds)=>{
    return moment(seconds*1000).format("YYYY/MM/DD")
}

const user_id = ref("")
const info = (id)=>{
    user_id.value = id
    show_look.value = true
}

import { useRem } from "@/stores/rem.js"
const rem = useRem()
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>