<template>
    <div class="trading-strategy">
        <!-- <Service /> -->
        <div class="title fn24 bold">{{$t('message.jiaoyi_celue')}}</div>
        <div class="list flex wrap ac">
            <div class="son" v-for="(item,index) in list" :key="index" @click="showDetail(item.id)">
                <img :src="item.image" alt="">
                <div class="name fn24 bold">{{item.strategy_name}}</div>
            </div>
        </div>
        <div class="flex flex-end h_center1 page" v-if="list.length!=0"><el-pagination background layout="prev, pager, next"
            :page-size="pagination.limit" :total="pagination.total" @change="turnPage" /></div>
        <div class="details fix" v-if="show">
            <div class="head h_center fn24 rel bold">
                <span>{{title}}</span>
                <img src="@/assets/33.png" @click="show=!show" class="close abs" alt="">
            </div>
            <div class="context fn20" v-html="content"></div>
        </div>
    </div>
</template>

<script setup>
import {ref,onMounted,inject} from "vue"
// import Service from "@/components/center/Service/Service"


import page from "@/api/page.js"
const $axios = inject("$axios")
const { url, list, method, query, turnPage, pagination, isLoading, handleSizeChange } = page($axios)
onMounted(() => {
    url.value = "/api/strategy/index"
    method.value="get"
    turnPage(pagination.page)
    // setTimeout(() => {
    //     // alert(111)
    //     // socketsj.ws.send('makfx&data+31171064')
    // }, 1000)
})

import { ElLoading,ElMessage } from 'element-plus'
const show = ref(false)
const content = ref("")
const title = ref("")
const showDetail = async (id)=>{
    const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    try{
        const result = await $axios.get(`/api/strategy/info?id=${id}`,{})
        content.value = result.data.strategy_desc
        title.value = result.data.strategy_name
        show.value=!show.value
    }catch(e){console.log(e)}
    loading.close()
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>