<template>
    <div class="update fix h_center">
        <div class="form rel">
            <img src="@/assets/33.png" class="close abs" @click="close" alt="">
            <div class="item rel">
                <div class="label fn24 bold">{{$t('message.yanzheng_yuan_youxiang_dizhi')}}</div>
                <input type="text" v-model="code" :placeholder="$t('message.shuru_youxiang_dizhi')" class="input fn20">
                <div class="btn fn24 h_center abs cursor bold" @click="oldSend">{{$t('message.fasong_yanzhengma')}}</div>
            </div>
            <div class="item rel">
                <div class="label fn24 bold">{{$t('message.shezhi_xin_youxiang')}}</div>
                <input type="text" v-model="newemail" :placeholder="$t('message.shuru_xin_youxiang_dizhi')" class="input fn20" >
            </div>
            <div class="item rel">
                <div class="label fn24 bold">{{$t('message.yanzheng_xin_youxiang_dizhi')}}</div>
                <input type="text" v-model="newcode" :placeholder="$t('message.shuru_xin_youxiang_yanzhengma')" class="input fn20">
                <div class="btn fn24 h_center abs cursor bold" @click="newSend">{{$t('message.fasong_yanzhengma')}}</div>
            </div>
            <!-- <div class="item">
                <div class="label fn24 bold">平台密码</div>
                <input type="password" placeholder="输入平台密码" class="input fn20" >
            </div> -->
            <div class="submit h_center fn28 bold" @click="submit">{{$t('message.tijiao')}}</div>
        </div>
    </div>
</template>

<script setup>
import {ref,inject} from "vue"
const emits = defineEmits(['close'])
const close = ()=>{
    emits('close')
}
import { storeToRefs } from 'pinia'
import {useUserStore} from "@/stores/user.js"
const {userInfo} = storeToRefs(useUserStore())

import { ElLoading, ElMessage } from 'element-plus'
const $axios = inject("$axios")
const code = ref("")
const oldSend = async ()=>{
    const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    try {
        const result = await $axios.post("/api/ems/commonCode",{email:userInfo.value.username,event:"update_email"})
        loading.close() 
        ElMessage.success(result.msg?result.msg:'发送成功')
    } catch (e) {
        loading.close() 
        console.log(e) 
    }
}

const newemail = ref("")
const newcode = ref("")
const newSend = async ()=>{
    const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    try {
        const result = await $axios.post("/api/ems/commonCode",{email:newemail.value,event:"update_email"})
        loading.close() 
        ElMessage.success(result.msg?result.msg:'发送成功')
    } catch (e) {
        loading.close() 
        console.log(e) 
    }
}
const submit = async ()=>{
    const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    try {
        const result = await $axios.post("/api/user/updateEmail",{
            email:userInfo.value.username,
            newemail:newemail.value,
            code:code.value,
            newcode:newcode.value
        })
        loading.close() 
        ElMessage.success(result.msg?result.msg:'修改成功')
        close()
    } catch (e) {
        loading.close() 
        console.log(e) 
    }
}

</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>