<template>
    <div>
        <div class="home">
            <div class="" ref="head_height">
                <Header />
            </div>
            <div class="section-top rel">
                <div ref="vantaRef" class="canvas" :style="rem.client_mode==2?`height:${rectStore.body_height}px`:''"></div>
                <div class="content abs h_center1" :style="rem.client_mode==2?`height:${rectStore.body_height}px`:''">
                    <div class="">
                        <div class="title fn48">
                            <img src="@/assets/2.png" class="rel" alt="">
                            <span>{{$t('message.home_area1')}}</span>
                        </div>
                        <div class="text fn18" :style="rem.client_mode==2?'font-size:0.24rem;':''">{{$t('message.home_area2')}}</div>
                        <div class="navs flex wrap ac" :class="{'between fn30':rem.client_mode==2,'fn18':rem.client_mode==1}">
                            <div class="son h_center cursor" v-for="(item,index) in cates" @click="showDetail(item.id)" :key="index">
                                <div class="">
                                    <img :src="item.image" alt="">
                                    <div class="name">{{item.title}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tuoguan" :style="rem.client_mode==2?`height:${rectStore.body_height}px`:''">
                <div class="title h_center bold" :class="{'fn48':rem.client_mode==2,'fn36':rem.client_mode==1}">
                    <img src="@/assets/2.png" class="rel" alt="">
                    <span>{{$t('message.tuoguan_fuwu')}}</span>
                </div>
                <div class="text fn22 ac">MakFX Hosting Services</div>
                <div class="navs flex wrap ac between" :class="{'fn24':rem.client_mode==2,'fn16':rem.client_mode==1}">
                    <div class="son rel">
                        <img src="@/assets/8.png" class="icon" alt="">
                        <div class=""><img src="@/assets/12.png" class="index" alt=""></div>
                        <div class="name">{{$t('message.home_area3')}}</div>
                        <div class="box abs load_animated move_ani"></div>
                    </div>
                    <div class="son rel">
                        <img src="@/assets/9.png" class="icon" alt="">
                        <div class=""><img src="@/assets/13.png" class="index" alt=""></div>
                        <div class="name">{{$t('message.home_area4')}}</div>
                        <div class="box abs load_animated move_ani"></div>
                    </div>
                    <div class="son rel">
                        <img src="@/assets/10.png" class="icon" alt="">
                        <div class=""><img src="@/assets/14.png" class="index" alt=""></div>
                        <div class="name">{{$t('message.home_area5')}}</div>
                        <div class="box abs load_animated move_ani"></div>
                    </div>
                    <div class="son rel">
                        <img src="@/assets/11.png" class="icon" alt="">
                        <div class=""><img src="@/assets/15.png" class="index" alt=""></div>
                        <div class="name">{{$t('message.home_area6')}}</div>
                        <div class="box abs load_animated move_ani"></div>
                    </div>
                </div>
            </div>
            <div class="moshi flex">
                <div class="left rel" v-if="rem.client_mode==1">
                    <img src="@/assets/16.png" class="photo" alt="">
                    <div class="dots abs">
                        <div class="son hover"></div>
                        <div class="son"></div>
                        <div class="son"></div>
                        <div class="son"></div>
                    </div>
                </div>
                <div class="right F1">
                    <div class="title flex wrap between h_center1" v-if="rem.client_mode==1">
                        <div class="lf">
                            <div class="box h_center fn36 bold">
                                <img src="@/assets/2.png" class="rel icon" alt="">
                                <span>{{$t('message.yingli_moxing')}}</span>
                            </div>
                            <div class="text fn22 ac">MAKFX PROFIT MODEL</div>
                        </div>
                        <img src="@/assets/17.png" class="index" alt="">
                    </div>
                    <div v-else>
                        <div class="title h_center bold fn48">
                            <img src="@/assets/2.png" class="rel" alt="">
                            <span>{{$t('message.yingli_moxing')}}</span>
                        </div>
                        <div class="text fn22 ac">MAKFX PROFIT MODEL</div>
                    </div>
                    
                    <div class="list">
                        <div class="son flex h_center1" :style="{backgroundImage:'url('+require('@/assets/19.png')+')'}">
                            <div class="lf h_center">
                                <img src="@/assets/21.png" class="icon" alt="">
                            </div>
                            <div class="context F1 hover">
                                <div class=" fn36 bold">50%</div>
                                <div class="text nowrap2 fn18">{{$t('message.home_area7')}}</div>
                            </div>
                        </div>
                        <div class="son flex h_center1" :style="{backgroundImage:'url('+require('@/assets/18.png')+')'}">
                            <div class="lf h_center">
                                <img src="@/assets/20.png" class="icon" alt="">
                            </div>
                            <div class="context  F1">
                                <div class=" fn36 bold">90%</div>
                                <div class="text nowrap2 fn18">{{$t('message.home_area8')}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="future">
                <div class="title h_center bold" :class="{'fn48':rem.client_mode==2,'fn36':rem.client_mode==1}">
                    <img src="@/assets/2.png" class="rel" alt="">
                    <span>{{$t('message.weilai_jihua')}}</span>
                </div>
                <div class="text fn22 ac">MAKFX FUTURE PLANS</div>
                <div class="list ac flex wrap between">
                    <div class="son">
                        <div class="box"><img src="@/assets/22.png" alt=""></div>
                        <div class="name bold" :class="{'fn36':rem.client_mode==2,'fn20':rem.client_mode==1}">{{$t('message.home_area9')}}</div>
                        <div class="text" :class="{'fn24':rem.client_mode==2,'fn16':rem.client_mode==1}">{{$t('message.home_area10')}}</div>
                    </div>
                    <div class="son">
                        <div class="box"><img src="@/assets/23.png" alt=""></div>
                        <div class="name bold" :class="{'fn36':rem.client_mode==2,'fn20':rem.client_mode==1}">{{$t('message.home_area11')}}</div>
                        <div class="text" :class="{'fn24':rem.client_mode==2,'fn16':rem.client_mode==1}">{{$t('message.home_area12')}}</div>
                    </div>
                    <div class="son" style="margin-right:0;">
                        <div class="box"><img src="@/assets/24.png" alt=""></div>
                        <div class="name bold" :class="{'fn36':rem.client_mode==2,'fn20':rem.client_mode==1}">{{$t('message.home_area13')}}</div>
                        <div class="text" :class="{'fn24':rem.client_mode==2,'fn16':rem.client_mode==1}">{{$t('message.home_area14')}}</div>
                    </div>
                </div>
            </div>
            <div class="out-box rel">
                <Figures />
                <div class="shuoming ac h_center abs" :class="{'fn24':rem.client_mode==2,'fn16':rem.client_mode==1}">{{$t('message.home_area12')}}</div>
            </div>
            <div class="footer fix" v-if="socket.list.length!=0">
                <div class="rollBox">
                    <div class="marquee">
                        <div class="box fn30 h_center1" v-for="(item,index) in socket.list" :key="index">
                            <span class="label">{{ item.keyword }}&nbsp;:&nbsp;</span>
                            <span class="val" :class="{'hover':item.float}">{{ item[item.keyword] }}</span>
                        </div>
                    </div>
                </div>
                <!-- <marquee class="marquee flex">
                    <div class="box fn30 h_center1" v-for="(item,index) in socket.list" :key="index">
                        <span class="label">{{ item.keyword }}&nbsp;:&nbsp;</span>
                        <span class="val" :class="{'hover':item.float}">{{ item[item.keyword] }}</span>
                    </div>
                </marquee> -->
            </div>
            <div class="details fix" v-if="show">
                <div class="head h_center fn24 rel bold">
                    <span>{{title}}</span>
                    <img src="@/assets/33.png" @click="show=!show" class="close abs" alt="">
                </div>
                <div class="context fn20" v-html="content"></div>
            </div>
        </div>
        
    </div>
    
</template> 

<script setup>
import {ref,onMounted,onUnmounted,onBeforeUnmount,inject} from "vue"
import Header from "@/components/Header/Header"
import Figures from "@/components/Figures/Figures"
import * as THREE from "three"
import HALO from "vanta/src/vanta.halo"
import {useSocket} from "@/stores/socket"
import {useRem} from "@/stores/rem.js"
import {useRectStore} from "@/stores/rect.js"
const rectStore  = useRectStore()

const rem = useRem()
const socket = useSocket()
if (socket.socketInit) {
    socket.socketInit(`wss://ws1.${window.location.hostname}`,(data)=>{
        socket.list = data.data.map(item=>{
            const keyword = Object.keys(item)
            item.keyword = keyword[0]
            item.float=false
            if(socket.list.length!=0){
                const index = socket.list.findIndex(child=>child[item.keyword]!=undefined)
                if(index!=-1 && parseInt(item[item.keyword]*100000)<parseInt(socket.list[index][item.keyword]*100000))item.float=true //false 红色  true绿色
            }
            return item
        })
    }) 
}
const vantaRef = ref(null)
let vantaEffect = ""
onMounted(()=>{
    init()
    if(rem.client_mode==1){
        vantaEffect= HALO({
            el: vantaRef.value,
            THREE: THREE,
            mouseControls: true,
            touchControls: true,
            gyroControls: false,
            minHeight: 200.0,
            minWidth: 200.0,
            baseColor: 0xd9,//基准颜色
            backgroundColor: 0x1D1B1B,//背景颜色 需十进制
            backgroundAlpha:0.00,
            amplitudeFactor: 1,//振幅因子
            xOffset: 0.3,//移动到X轴
            yOffset: 0,//移动到Y轴
            size: 1.5,//大小
        })
    }else{
        vantaEffect= HALO({
            el: vantaRef.value,
            THREE: THREE,
            mouseControls: true,
            touchControls: true,
            gyroControls: false,
            minHeight: 150.0,
            minWidth: 150.0,
            baseColor: 0xd9,//基准颜色
            backgroundColor: 0x1D1B1B,//背景颜色 需十进制
            backgroundAlpha:0.00,
            amplitudeFactor: 1,//振幅因子
            xOffset: 0,//移动到X轴
            yOffset: -0.15,//移动到Y轴
            size: 1.2,//大小
        })
    }
    setTimeout(()=>{
        // if(socket.ws)socket.ws.send('makfx')
    },1000)
}) 
onUnmounted(()=>{
    if(vantaEffect)vantaEffect.destroy()
})

const height = ref(0)
const {body_height}  = useRectStore()
height.value = body_height


const $axios = inject("$axios")
const cates = ref([])
const init = async ()=>{
    $axios.get("/api/index/article?cate_id=9",{}).then(
        res=>{
            cates.value =res.data
        },
        err=>console.log(err)
    )
}


import { ElLoading,ElMessage } from 'element-plus'
const content = ref("")
const title = ref("")
const show = ref(false)
const showDetail = async (id)=>{
    const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    try{
        const result = await $axios.get(`/api/index/articleinfo?id=${id}`,{})
        content.value = result.data.content
        title.value = result.data.title
        show.value=!show.value
    }catch(e){console.log(e)}
    loading.close()
}
</script>

<style lang="scss" scoped>
@import "./home.scss";
</style>