<template>
    <div class="ask fix h_center">
        <div class="content rel">
            <img src="@/assets/33.png" class="close abs" @click="close" alt="">
            <div class="name fn30 bold ac">{{name}}</div>
            <div class="text fn24 ac">{{text}}</div>
            <div class="btn h_center fn28 bold" @click="submit">{{confirm}}</div>
        </div>
    </div>
</template>

<script setup>
defineProps({
    name:String,
    text:String,
    confirm:String
})

const emits = defineEmits(["confirm","close"])
const close = ()=>{
    emits('close')
}
const submit = ()=>{
    emits("confirm")
}
</script>

<style lang="scss" scoped>
.pc{
    .ask{
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.75);
        left:0;
        bottom:0;
        z-index:99;
        .content{
            width: 50%;
            background-color: #fff;
            border-radius:24px;
            box-sizing: border-box;
            padding: 50px;
            .close{
                width: 30px;
                height: 30px;
                right:24px;
                top:24px;
                cursor: pointer;
            }
            .text{
                margin-top:50px;
            }
            .btn{
                width: 280px;
                height: 80px;
                background-color: #43F9FF;
                border-radius:20px;
                color:#222;
                margin: 0 auto;
                margin-top:50px;
                cursor: pointer;
            }
        }
    }
}
.mobile{
    .ask{
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.75);
        left:0;
        bottom:0;
        z-index:2;
        .content{
            width: 75%;
            background-color: #fff;
            border-radius:0.24rem;
            box-sizing: border-box;
            padding: 0.24rem;
            .close{
                width: 0.3rem;
                height: 0.3rem;
                right:0.24rem;
                top:0.24rem;
                cursor: pointer;
            }
            .text{
                margin-top:0.3rem;
            }
            .btn{
                width: 2rem;
                height: 0.8rem;
                background-color: #43F9FF;
                border-radius:0.2rem;
                color:#222;
                margin: 0 auto;
                margin-top:0.5rem;
                cursor: pointer;
            }
        }
    }
}
</style>