const en = {
    yuyan:"language",
    login:"login",
    yue:"balance",
    zhanghao_tuoguan:"Account hosting",
    shuru_fuwuqi_dizhi:"Enter server address",
    shuru_mt4_zhanghao:"Enter MT4 account",
    shuru_mt4_mima:"Enter MT4 password",
    xuanze_celue:"selection strategy",
    tijiao:"Submit",
    wode_zhanghu:"My account",
    xiugai_zhanghu:"Modify account",
    xiugai_mima:"Change password",
    zhanghu_yue:"Account balance",
    zhanghu_shuliang:"Number of accounts",
    chongzhi:"Recharge",
    zhuanzhang:"Transfer",
    zhuanzhang_jine:"Transfer amount",
    shuru_zhuanzhang_jine:"Enter transfer amount",
    jiaoyi_jilu:"Transaction records",
    tixian:"Withdrawal",
    dingdan_bianhao:"Order number",
    quzhifu:"Go pay",
    gei:"to",
    shijian:"time",
    yanzheng_youxiang_dizhi:"Verify email address",
    shuru_yanzhengma:"Enter verification code",
    fasong_yanzhengma:"verification code",
    yuan_pingtai_mima:"Original platform password",
    shuru_pingtai_mima:"Enter platform password",
    xin_pingtai_mima:"New platform password",
    yanzheng_yuan_youxiang_dizhi:"Verify the original email address",
    yanzheng_xin_youxiang_dizhi:"Verify new email address",
    shuru_youxiang_dizhi:"Enter email address",
    shuru_xin_youxiang_dizhi:"Enter a new email address",
    shezhi_xin_youxiang:"Enter a new email address to set up a new email address",
    shuru_xin_youxiang_yanzhengma:"Enter the new email verification code",
    neibu_zhuanzhang:"internal transfer",
    zhuandao_qianbao:"Transfer to wallet",
    tibi:"Withdrawal of coins",
    youxiang_yanzheng:"Email verification",
    tibi_jine:"Withdrawal amount",
    chongzhi_jine:"Recharge amount",
    shuru_chongzhi_jine:"Enter recharge amount",
    shuru_tibi_jine:"Enter withdrawal amount",
    duifang_youxiang:"The other party's email",
    shuru_duifang_youxiang:"Enter the recipient's email address",
    tixing:"remind",
    mashang_jihuo:"You haven't activated your wallet yet. Would you like to activate it now?",
    liji_jihuo:"Activate Now",
    qingzai:"Please check in the",
    shi:"hour",
    fen:"minute",
    miao:"second",
    qian:"before",
    wancheng_shixiao:"Complete recharge, otherwise it will become invalid",
    shiqu_jihui:"Complete the account opening, otherwise you will miss this opportunity!",
    benci_shixiao:"This account opening invitation has expired",
    yishixiao_chongzhi:"The QR code has expired, please recharge again",
    shuaxin:"Refresh",
    chongzhi_chenggong:"Recharged successfully!",
    guanbi:"close",
    wode_tuandui:"My team",
    tuandui_renshu:"Team size",
    kaihu:"Opening an account",
    kaihu_youxiang:"Account opening email",
    shuru_kaihu_youxiang:"Enter account opening email",
    tuandui_liebiao:"Team List",
    zhanghao:"Account",
    kaihu_riqi:"Account opening date",
    chakan:"check",
    chakan_zhanghu_xinxi:"View account information",
    faqi_kaihu_yaoqing:"Initiate an account opening invitation",
    gailan:"overview",
    wode_tuoguan:"My hosting",
    zi_zhanghu_shuliang:"Number of sub accounts",
    gonggao_liebiao:"Announcement List",
    jiaoyi:"transaction",
    dingdanhao:"Order number",
    dingdan_yinkui:"Order profit and loss",
    shou_shu:"Hand count",
    kaicang_shijian:"Open Time",
    kaicang_jiage:"Opening price",
    jiaoyi_huobi:"Trading currency",
    jiaoyi_leixing:"Transaction type",
    lianxi_kefu:"Contact customer service",
    jiaoyi_celue:"Trading strategy",
    zhanghu_tuoguan:"Account custody",
    tuoguan_zhong:"In custody",
    jiechu_tuoguan:"Release from custody",
    jinyong:"Disabled",
    zuixin_shouyi:"Latest Revenue",
    leiji_shouyi:"Accumulated revenue",
    nianhua:"7-day annualized",
    chakan_jiaoyi:"View transactions",
    duihuan_shuoming:"Exchange Instructions",
    wode_qianbao:"My wallet",
    wode_yue:"My balance",
    qianbao_dizhi:"Wallet address",
    qianbao_shuoming:"Wallet Description",
    jihuo_qianbao:"Activate wallet",
    duihuan:"exchange",
    huanying_nin:"Welcome",
    qingshuru_mima:"Please enter password",
    zhanghu_mima:"Account password",
    queren_mima:"Confirm password",
    qing_queren_mima:"Please confirm the password again",
    queren_kaihu:"Confirm account opening",
    qingshuru_zhanghu:"Please enter the account name",
    tuoguan_fuwu:"hosted services",
    yingli_moxing:"Profit model",
    weilai_jihua:"Future plans",
    home_area1:"Dedicated to providing a trading strategy with high stable returns and low risk for foreign exchange investors",
    home_area2:"Currently, a closed operating model will be adopted. Due to market chaos, makfx.com is temporarily unable to register independently. When cross platform compatible hosting services reach our expected level, we will open up the closed operation of makfx.com",
    home_area3:"We have independently developed custody monitoring to provide real-time transaction data for each custody account, and a professional team with 5X24 hours of real-time monitoring to safeguard your custody transactions!",
    home_area4:"You only need to pay a very small amount of custody fees to receive our complete support and services. We will not participate in your profit distribution. On the contrary, we will bear all custody fees for the custody period required for your stop loss!",
    home_area5:"The transaction hosting servers all adopt a master-slave mode, with a stable trading environment of 99.99% high availability. We have installed hosting servers in major data centers around the world, providing stable transaction support for different retail trading platforms!",
    home_area6:"We will continue to update the MakFX EA code and also update more risk models for you to choose from. Different risk models are based on the MakFX core and only regulate different and appropriate profitability and risk levels!",
    home_area7:"Adopting a robust setting plan, we provide you with an annualized 50% expected return. Expected monthly return: 4% -5%. Expected annual stop loss frequency: 1/15%. Note: There are almost no stop loss situations (except for extremely special market conditions, such as the Black Swan event)",
    home_area8:"Using a normal setting plan, we provide you with an annualized 90% expected return. Expected monthly return: 7% -10%. Expected annual stop loss frequency: 1/15%. Note: Stop loss situations do not occur in many years",
    home_area9:"A low threshold is a sign of successful strategy",
    home_area10:"The current MakFX strategy has controlled the minimum entry capital to 2000 USD, and the profitability and risk level of the strategy depend on the size of leverage and position. In the future, we will further optimize the strategy to achieve a lower threshold and higher risk characteristic of the strategy",
    home_area11:"MakFX Open Platform Operations",
    home_area12:"Currently, a closed operating model will be adopted. Due to market chaos, makfx.com is temporarily unable to register independently. When cross platform compatible hosting services meet our expectations, we will open up the closed operation of makfx.com",
    home_area13:"Controllable EA Program Collaboration Model",
    home_area14:"The MakFX EA code references a lot of DLL dynamic connection libraries, and we are also integrating most of the features and committed to launching an online EA program to deal with users who are not willing to host accounts. In the future, you can download our EA program and log in using the Makfx.com platform account to conduct automatic transactions in your own production environment",
    text_area:"MakFX is committed to providing a trading strategy with high stable returns and low risk for foreign exchange investors. In MakFX's core trading strategy, EURUSD (Euro to USD) and (USD to Swiss Franc) are the core trading pairs. Due to Switzerland being a neutral country, the stability of the Swiss Franc currency exchange rate is strong. The exchange rate fluctuations of EURUSD and USDCHF are mostly in the opposite direction (not absolute reverse or absolute symmetry), and the MakFX strategy calculates historical exchange rate values for up to 10 years in real-time, using special algorithms to calculate the twist for trading.",
    text_area2:"The strategy will involve a certain amount of additional positions, but the controllability is very high. The basic additional position is only 1.1 times, and the maximum will not exceed 1.3 times. Due to the nature of national policies that will ultimately stabilize the currency, it will promote the return of the twist to a normal value to achieve profitability! When necessary, we will add a currency pair EURCHF (Euro to Swiss Franc) for exchange rate linkage during the trading process to cope with large-scale risks. The maximum stop loss line for a single strategy is 30%, which is generally set at 15% -20%. In terms of profitability, MakF will provide multiple models, each with different risk resistance and profitability, suitable for various types of investors. Currently, there are 4-5% ultra stable and healthy models, 7-10% popular models, and 15% -20% radical models."
}

export default en