<template>
    <div class="wallet">
        <div class="list">
            <div class="title fn24 bold">{{$t('message.duihuan_shuoming')}}</div>
            <div class="account1 flex wrap between h_center1 fn18" v-html="get_public.public_info.usdt_desc"></div>
        </div>
    </div>
</template>

<script setup>
import {onMounted} from "vue"
import { storeToRefs } from 'pinia'
import {useUserStore} from "@/stores/user.js"
const {userInfo} = storeToRefs(useUserStore())
const clipboardObj = navigator.clipboard
onMounted(() => {
    // setTimeout(() => {
    //     // alert(111)
    //     // socketsj.ws.send('makfx&data+31171064')
    // }, 1000)
})


const copy = ()=>{
    clipboardObj.writeText(String(userInfo.value.wallet_address)).then(
        res=>{
            alert("复制成功")
        }
    )
}

import { usePublicStore } from "@/stores/public.js"
const get_public = usePublicStore()


</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>