import {ref} from "vue"
import { defineStore } from 'pinia'
import {takeChatWebSoket} from "@/utils/wsocket.js"
export const useSocket = defineStore('socket',()=>{
    const ws = ref("")
    const ws_url = ref("")
    const list = ref([])
    // const pong = ref("")
    const socketInit = (url,callBack)=>{
        takeChatWebSoket((val)=>{
            ws.value = val
            ws.value.onmessage=(obj)=>{
                if(obj.data){
                    try{
                        const data = JSON.parse(obj.data)
                        callBack(data)
                    }catch(e){
                        // console.log(e)
                    }
                }
            }
        },url)
    }
    return {
        ws_url,
        ws,
        list,
        socketInit,
    }
})