import {ref,computed} from "vue"
import { defineStore } from 'pinia'
import { useI18n } from 'vue-i18n'
export const useLanguage = defineStore('language',()=>{
    const { locale,} = useI18n()
    const options = ref([
        {
            value: "zh",
            label: "繁體中文",
        },{
            value: "en",
            label: "English",
        },
    ])
    const handleLocal = (value)=>{
        locale.value = value
        localStorage.setItem('language', locale.value)
    }
    const name = computed(()=>{
        return  locale.value=='en'?'English':'繁體中文'
    })
    return {
        name,
        options,
        handleLocal
    }
})