import * as THREE from 'three'
export default ()=>{
    const initFigures = ()=>{
        const group = new THREE.Group()
        for(let i=0;i<10;i++){
            const geometry = new THREE.PlaneGeometry(60,300)
            const texLoader = new THREE.TextureLoader()
            const texture = texLoader.load(require("@/assets/48.png"))
            texture.repeat.set(1/5,1)
            const material = new THREE.MeshBasicMaterial({
                map: texture,
                transparent:true,
                opacity:1,
                side:THREE.DoubleSide
            })
            const mesh = new THREE.Mesh(geometry, material)
            mesh.position.set(i*50-250,i*20-60,Math.random()*100-50)
            mesh.scale.set(0.05,0.05,0.05)
            let c = 0
            let t = 0
            mesh.updateTexture=()=>{
              t+=0.05
              if(t>5)t=0
              c=5-Math.floor(t%5)-1
              texture.offset.x=c/5
            }
            group.add(mesh)
        }
        return group
    }
    const FiguresStart = (group)=>{
        for(let i=0;i<group.children.length;i++){
            group.children[i].updateTexture()
            group.children[i].position.y+=0.18
            if(group.children[i].position.y>100){
                group.children[i].position.set(Math.random()*200-100,0,Math.random()*100-50)
                group.children[i].position.y=-60
            }
        }
    }
    //扩散波效果
    const vextureShader = `
        varying vec2 vUv;
        varying vec3 fNormal;
        varying vec3 vPosition;
        void main()
        {
        vUv = uv;
        fNormal=normal;
        vPosition=position;
        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
        }
    `
    const fragmentShader = `
        varying vec2 vUv;
        uniform float iTime;
        uniform sampler2D colorTexture;
        vec3 hsb2rgb(const vec3 c) {
        vec3 rgb = clamp(abs(mod(c.x*6.0+vec3(0.0, 4.0, 2.0), 6.0)-3.0)-1.0, 0.0, 1.0);
        rgb = rgb*rgb*(3.0-2.0*rgb);
        return c.z * mix( vec3(1.0), rgb, c.y);
        }
        void main() {
        vec2 uv = vUv;
        vec2 p = 2.0*uv.xy - vec2(1., 1.) ; // center what being drawn
        float r = length(p) * 2.; //圈数量
        vec3 color = hsb2rgb(vec3(0.4, 1., 1.)); //0.24, 0.7, 0.5
        vec4 textureValue = texture2D( colorTexture, vUv );
        float a = pow(r, 2.0); //圈数量
        float b = sin(r * 0.8 - 1.6);
        float c = sin(r - .10);
        float s = sin(a - iTime * 3.0 + b) * c;
        color *= abs(1.0 / (s * 30.)) - 0.1; //初始圈大小
        gl_FragColor = vec4(color, 1.);
        }
    `
    let uniforms = null
    const initFloor=()=> {
        const geometry = new THREE.CircleGeometry(100, 64);
        uniforms = {
            iTime: { value: 0.1 },
        }
        const material = new THREE.ShaderMaterial({
            uniforms: uniforms,
            vertexShader: vextureShader,
            fragmentShader: fragmentShader,
            blending: THREE.AdditiveBlending,
            transparent: true,
            opacity: 0
        })
        const plane = new THREE.Mesh(geometry, material);
        plane.rotateX(-Math.PI / 2)
        plane.position.y = -0.015
        return plane
    }
    const clock = new THREE.Clock()
    const floorStart = ()=>{
        const timer = clock.getDelta()
        uniforms.iTime.value += Math.sin(timer) / 3
        if (uniforms.iTime.value > 1.0) {
            uniforms.iTime.value = 0.0
        }
    }
    //画2d圆环
    const drawCircular = (startX=60,endX=65)=>{
        const angle = Math.PI*2/3
        const group = new THREE.Group()
        for(let i =0;i<3;i++){
            const curve = new THREE.SplineCurve([
                new THREE.Vector2(startX, 0),
                new THREE.Vector2(endX, 0),
            ])
            const pointsArr = curve.getPoints(50);
            const geometry = new THREE.LatheGeometry(pointsArr, 50,angle*i,angle*0.9)
            const material = new THREE.MeshLambertMaterial({
                color: 0x43F9FF,
                side:THREE.DoubleSide,
            })
            const mesh = new THREE.Mesh(geometry, material)
            group.add(mesh)
        }
        return group
    }
    //使用粒子系统创建星空背景
    const starScene = ()=>{
        const geom = new THREE.Geometry()
        const material = new THREE.ParticleBasicMaterial({
            size: 0.01,
            vertexColors:true
        });
        const n = 1200
        for (let i = 0; i < 3000; i++) {
            const particle = new THREE.Vector3(
                (Math.random() - 0.5) * n,
                (Math.random() - 0.5) * n,
                (Math.random() - 0.5) * n
            );
            geom.vertices.push(particle)
            const color_ = Math.random()
            geom.colors.push(new THREE.Color(color_, color_, color_ * 0.6))
        }
        const cloud = new THREE.ParticleSystem(geom, material)
        return cloud
    }
    return {
        initFigures,
        FiguresStart,
        initFloor,
        floorStart,
        drawCircular,
        starScene
    }
}

