import {reactive,ref} from "vue"
export default (axios)=>{
    const pagination = reactive({
        page: 1, 
        total: 0, 
        limit: 10,
    })
    const pageSizes = ref([5, 20, 30, 40])
    const query = reactive({})
    let method = ref('get')
    let isLoading = ref(false)
    let list = ref([])
    let url = ref("")
    const getParams = ()=>{
        return Object.assign({},query,pagination)
    }
    const turnPage = (page)=>{
        return new Promise(async (resolve,reject)=>{
            pagination.page = page
            list.value = []
            if(method.value=='get'){
                get().then(
                    res=>resolve(),
                    err=>reject(err)
                )
            }else{
                post().then(
                    res=>resolve(),
                    err=>reject(err)
                )
            }
        })
    }
    const get = ()=>{
        return new Promise((resolve,reject)=>{
            isLoading.value = true
            let params = getParams()
            axios.get(url.value,{params: params}).then(
                res=>{
                    setPagination(res)
                    setTimeout(()=>isLoading.value = false,300)
                    resolve()
                },
                err=>{
                    setTimeout(()=>isLoading.value = false,300)
                    reject(err)
                    pagination.total=0
                }
            )
        })
    }
    const post = ()=>{
        return new Promise((resolve,reject)=>{
            isLoading.value = true
            axios.post(url.value,getParams()).then(
                res=>{
                    setTimeout(()=>isLoading.value = false,300)
                    setPagination(res)
                    resolve()
                },
                err=>{
                    setTimeout(()=>isLoading.value = false,300)
                    reject(err)
                    pagination.total=0
                }  
            )
        })
    }
    const setPagination = (data)=>{
        pagination.total = data.data.total * 1
        list.value = data.data.data
    }
    const handleSizeChange = ()=>{

    }
    return {
        method,
        isLoading,
        pagination,
        query,
        list,
        pageSizes,
        getParams,
        turnPage,
        handleSizeChange,
        url,
    }
}